const styles = {
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--gray-800)',
    borderRadius: 0,
  },
};

export default styles;
