import LoadingWrapper from 'components/LoadingWrapper';
import { useResizeListener } from 'primereact/hooks';
import { useCollections } from 'Providers/CollectionsProviders';
import { useEffect, useMemo, useState } from 'react';
import Item from './components/Item';
import { Skeleton } from 'primereact/skeleton';
import { categories } from 'screens/Home/utils';
import { useNavigate } from 'react-router-dom';
import { CARD_WIDTH } from './components/Item/contants';
import styles from './styles.module.css';
import './styles.css';

const GaleryCollections = () => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({ width: 0, height: 0 });
  const [bindWindowResizeListener, unbindWindowResizeListener] =
    useResizeListener({
      listener: (event) => {
        setEventData({
          width: event.currentTarget.innerWidth,
          height: event.currentTarget.innerHeight,
        });
      },
    });
  useEffect(() => {
    setEventData({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  useEffect(() => {
    bindWindowResizeListener();
    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);

  const arrayObjects = Array(6).fill({});
  const collections = useCollections().collections;
  const maxCollectionScrollView = useMemo(() => {
    if (eventData.width < 480) {
      return eventData.width * 0.825;
    }
    if (eventData.width < 768) {
      return eventData.width * 0.825;
    }
    return eventData.width * 0.9;
  }, [eventData.width]);

  const renderItems = (category) => {
    if (!collections) {
      return arrayObjects.map(() => (
        <Skeleton width="10rem" height="4rem" borderRadius="16px"></Skeleton>
      ));
    }
    return collections
      ?.filter((item) => item.category === category)
      .sort((a, b) => {
        return parseInt(b.completionDate) - parseInt(a.completionDate);
      })
      .map((item) => <Item item={item} />);
  };
  const scrollLeft = (category) => {
    const container = document.getElementById(category);
    const howScrollLeft = eventData.width < 768 ? -360 : -300;
    container.scrollBy({
      left: howScrollLeft,
      behavior: 'smooth',
    });
  };

  const scrollRight = (category) => {
    const container = document.getElementById(category);
    const howScrollRight = eventData.width < 768 ? 360 : 300;
    container.scrollBy({
      left: howScrollRight,
      behavior: 'smooth',
    });
  };

  const haveScrollHorizontal = (category) => {
    const manyItemsWidth =
      collections?.filter((item) => item.category === category)?.length *
        CARD_WIDTH ?? CARD_WIDTH;

    return manyItemsWidth > maxCollectionScrollView;
    // TODO SCROLL CONTROLLER
  };

  const withScrollManagerComponent = (children, category) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        paddingBottom: 24,
      }}
    >
      <div
        style={{
          marginRight: 4,
          padding: 6,
          backgroundColor: 'var(--gray-300)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 5,
          cursor: 'pointer',
          height: 80,
        }}
        className="scrollBarDirection button"
        onClick={() => scrollLeft(category)}
        id={'scrollViewLeft'}
      >
        <i
          id={'detailItemCartIcon'}
          className="pi circle-left pi-chevron-left"
          style={{ fontSize: '1.3rem', color: 'var(--gray-50)' }}
        ></i>
      </div>
      {children}
      <div
        style={{
          marginLeft: 4,
          padding: 6,
          backgroundColor: 'var(--gray-300)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 5,
          cursor: 'pointer',
          height: 80,
        }}
        className="scrollBarDirection button"
        onClick={() => scrollRight(category)}
        id={'scrollViewLeft'}
      >
        <i
          id={'detailItemCartIcon'}
          className="pi circle-left pi-chevron-right"
          style={{ fontSize: '1.3rem', color: 'var(--gray-50)' }}
        ></i>
      </div>
    </div>
  );

  const goToCollection = (categoryName) => {
    if (categoryName) navigate(`/collection?categoryName=${categoryName}`);
  };

  const CategoryRender = (category) => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            fontSize: '2em',
            fontWeight: 600,
            paddingBottom: 12,
          }}
          className={styles.collection}
        >
          <p
            style={{ color: 'var(--gray-900)', cursor: 'pointer' }}
            onClick={() => goToCollection(category)}
          >{`${category}s`}</p>
        </div>
        <div
          id={category}
          style={{
            width: maxCollectionScrollView,
            maxWidth: maxCollectionScrollView,
            overflowX: 'auto',
            borderRadius: 5,
          }}
          className="scrollContainer"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: 5,
            }}
          >
            {renderItems(category)}
          </div>
        </div>
      </div>
    );
  };
  return (
    <LoadingWrapper loading={!collections}>
      {categories
        ?.filter((categoryName) =>
          collections?.find(
            (itemCollection) => itemCollection?.category === categoryName,
          ),
        )
        .map((category) =>
          haveScrollHorizontal(category)
            ? withScrollManagerComponent(CategoryRender(category), category)
            : CategoryRender(category),
        )}
    </LoadingWrapper>
  );
};

export default GaleryCollections;
