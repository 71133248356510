import LoadingContent from 'components/LoadingContent';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { instance } from 'services/api';
import './styles.css';
import { useCollections } from 'Providers/CollectionsProviders';

const ResolutionView = ({ eventData }) => {
  const [searchParams] = useSearchParams();
  const preference_id = searchParams.get('preference_id');
  const [payment, setPayment] = useState(null);
  const { clearShoppingCart } = useCollections();

  useEffect(() => {
    let isMounted = true;
    const getPayment = async () => {
      try {
        if (isMounted) {
          const response = await instance.get(
            `api/get_payment?preference_id=${preference_id}`,
          );
          if (response.data.ok) {
            if (response.data.payment.has_payed) {
              clearShoppingCart();
            }
            setPayment(response.data.payment);
          }
        }
      } catch (error) {
        /* empty */
      }
    };

    getPayment();

    return () => {
      isMounted = false;
    };
  }, []);

  if (!payment) {
    return <LoadingContent minHeight={eventData.height * 0.65} />;
  }

  return (
    <div className="container">
      <div className="resumenContainer">
        <div className="circle-check">
          <i className="pi pi-check-circle icon-check" />
        </div>
        <div style={{ fontSize: '1.2em', fontWeight: 600 }}>
          Tu compra se ha realizado satisfactoriamente.
        </div>
        <div>{`Número de transacción ${payment.id}`}</div>
        <div className="gratitude">
          <div>Muchas gracias por apoyar mi arte.</div>
          <div>Estaré contactándote a la brevedad.</div>
        </div>
      </div>
    </div>
  );
};

export default ResolutionView;
