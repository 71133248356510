import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';

const LoadingWrapper = ({ loading, children }) => {
  if (loading) {
    return (
      <div className="card flex justify-content-center">
        <ProgressSpinner aria-label="" />
      </div>
    );
  }

  return children;
};

export default LoadingWrapper;
