import React, { useEffect } from 'react';
import { useCollections } from 'Providers/CollectionsProviders';
import Item from 'components/ContainerCollections/components/GaleryCollections/components/Item';
import { v4 as uuidv4 } from 'uuid';
import { Skeleton } from 'primereact/skeleton';
import { isSearchContained } from 'screens/Home/utils';
import LoadingContent from 'components/LoadingContent';

const SearchResultView = ({ eventData }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const searchParams = urlParams.get('search');
  const { collections, search, newSearch } = useCollections();
  const galleryCategory =
    search.length === 0
      ? collections
      : collections?.filter((item) => isSearchContained(search, item.name));
  const arrayObjects = Array(6).fill({});

  if (!collections) {
    return <LoadingContent minHeight={eventData.height * 0.65} />;
  }

  if (!galleryCategory) {
    return arrayObjects.map(() => (
      <Skeleton width="10rem" height="4rem" borderRadius="16px"></Skeleton>
    ));
  }
  useEffect(() => {
    newSearch(searchParams);
  }, []);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          fontSize: '2em',
          fontWeight: 600,
          paddingBottom: 12,
          paddingTop: 24,
        }}
        className="collection"
      >
        <p
          style={{ color: 'var(--gray-900)', cursor: 'pointer' }}
        >{`Resultados`}</p>
      </div>

      <div
        style={{
          width: eventData.width * 0.9,
          minHeight: eventData.height * 0.7,
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {galleryCategory.length !== 0 ? (
          galleryCategory.map((item) => (
            <div style={{ paddingBottom: 16 }}>
              <Item key={uuidv4()} item={item} />
            </div>
          ))
        ) : (
          <div style={{ color: 'var(--gray-800)', fontSize: '1.5em' }}>
            La búsqueda no tiene resultados.
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResultView;
