import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingContent = ({ minHeight }) => {
  return (
    <div
      style={{
        minHeight,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ProgressSpinner />
    </div>
  );
};

export default LoadingContent;
