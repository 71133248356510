export const COUNTRIES = [
  { name: 'Argentina', code: 'AR', value: 'Argentina' },
  { name: 'Australia', code: 'AU', value: 'Australia' },
  { name: 'Austria', code: 'AT', value: 'Austria' },
  { name: 'Belgium', code: 'BE', value: 'Belgium' },
  { name: 'Brasil', code: 'BR', value: 'Brasil' },
  { name: 'Canada', code: 'CA', value: 'Canada' },
  { name: 'Chile', code: 'CL', value: 'Chile' },
  { name: 'China', code: 'CN', value: 'China' },
  { name: 'Colombia', code: 'CO', value: 'Colombia' },
  { name: 'Denmark', code: 'DK', value: 'Denmark' },
  { name: 'Egypt', code: 'EG', value: 'Egypt' },
  { name: 'Finland', code: 'FI', value: 'Finland' },
  { name: 'France', code: 'FR', value: 'France' },
  { name: 'Germany', code: 'DE', value: 'Germany' },
  { name: 'Greece', code: 'GR', value: 'Greece' },
  { name: 'India', code: 'IN', value: 'India' },
  { name: 'Ireland', code: 'IE', value: 'Ireland' },
  { name: 'Italy', code: 'IT', value: 'Italy' },
  { name: 'Japan', code: 'JP', value: 'Japan' },
  { name: 'Mexico', code: 'MX', value: 'Mexico' },
  { name: 'Netherlands', code: 'NL', value: 'Netherlands' },
  { name: 'New Zealand', code: 'NZ', value: 'New Zealand' },
  { name: 'Norway', code: 'NO', value: 'Norway' },
  { name: 'Peru', code: 'PE', value: 'Peru' },
  { name: 'Poland', code: 'PL', value: 'Poland' },
  { name: 'Portugal', code: 'PT', value: 'Portugal' },
  { name: 'Russia', code: 'RU', value: 'Russia' },
  { name: 'South Africa', code: 'ZA', value: 'South Africa' },
  { name: 'South Korea', code: 'KR', value: 'South Korea' },
  { name: 'Spain', code: 'ES', value: 'Spain' },
  { name: 'Sweden', code: 'SE', value: 'Sweden' },
  { name: 'Switzerland', code: 'CH', value: 'Switzerland' },
  { name: 'Turkey', code: 'TR', value: 'Turkey' },
  { name: 'United Arab Emirates', code: 'AE', value: 'United Arab Emirates' },
  { name: 'United Kingdom', code: 'GB', value: 'United Kingdom' },
  { name: 'United States', code: 'US', value: 'United States' },
  { name: 'Uruguay', code: 'UY', value: 'Uruguay' },
  { name: 'Venezuela', code: 'VE', value: 'Venezuela' },
  { name: 'Vietnam', code: 'VN', value: 'Vietnam' },
];

export const PROVINCIES = {
  Argentina: [
    { name: 'Buenos Aires', code: 'AR-B' },
    { name: 'Catamarca', code: 'AR-K' },
    { name: 'Chaco', code: 'AR-H' },
    { name: 'Chubut', code: 'AR-U' },
    { name: 'Córdoba', code: 'AR-X' },
    { name: 'Corrientes', code: 'AR-W' },
    { name: 'Entre Ríos', code: 'AR-E' },
    { name: 'Formosa', code: 'AR-P' },
    { name: 'Jujuy', code: 'AR-Y' },
    { name: 'La Pampa', code: 'AR-L' },
    { name: 'La Rioja', code: 'AR-F' },
    { name: 'Mendoza', code: 'AR-M' },
    { name: 'Misiones', code: 'AR-N' },
    { name: 'Neuquén', code: 'AR-Q' },
    { name: 'Río Negro', code: 'AR-R' },
    { name: 'Salta', code: 'AR-A' },
    { name: 'San Juan', code: 'AR-J' },
    { name: 'San Luis', code: 'AR-D' },
    { name: 'Santa Cruz', code: 'AR-Z' },
    { name: 'Santa Fe', code: 'AR-S' },
    { name: 'Santiago del Estero', code: 'AR-G' },
    { name: 'Tierra del Fuego', code: 'AR-V' },
    { name: 'Tucumán', code: 'AR-T' },
  ],
  Australia: [
    { name: 'New South Wales', code: 'AU-NSW' },
    { name: 'Victoria', code: 'AU-VIC' },
    { name: 'Queensland', code: 'AU-QLD' },
    { name: 'South Australia', code: 'AU-SA' },
    { name: 'Western Australia', code: 'AU-WA' },
    { name: 'Tasmania', code: 'AU-TAS' },
    { name: 'Australian Capital Territory', code: 'AU-ACT' },
    { name: 'Northern Territory', code: 'AU-NT' },
  ],
  Austria: [
    { name: 'Burgenland', code: 'AT-1' },
    { name: 'Carinthia', code: 'AT-2' },
    { name: 'Lower Austria', code: 'AT-3' },
    { name: 'Upper Austria', code: 'AT-4' },
    { name: 'Salzburg', code: 'AT-5' },
    { name: 'Styria', code: 'AT-6' },
    { name: 'Tyrol', code: 'AT-7' },
    { name: 'Vorarlberg', code: 'AT-8' },
    { name: 'Vienna', code: 'AT-9' },
  ],
  Belgium: [
    { name: 'Antwerp', code: 'BE-VAN' },
    { name: 'East Flanders', code: 'BE-VOV' },
    { name: 'Flemish Brabant', code: 'BE-VBR' },
    { name: 'Hainaut', code: 'BE-WHT' },
    { name: 'Liège', code: 'BE-WLG' },
    { name: 'Limburg', code: 'BE-VLI' },
    { name: 'Luxembourg', code: 'BE-WLX' },
    { name: 'Namur', code: 'BE-WNA' },
    { name: 'Walloon Brabant', code: 'BE-WBR' },
    { name: 'West Flanders', code: 'BE-VWV' },
  ],
  Brasil: [
    { name: 'Acre', code: 'BR-AC' },
    { name: 'Alagoas', code: 'BR-AL' },
    { name: 'Amapá', code: 'BR-AP' },
    { name: 'Amazonas', code: 'BR-AM' },
    { name: 'Bahia', code: 'BR-BA' },
    { name: 'Ceará', code: 'BR-CE' },
    { name: 'Distrito Federal', code: 'BR-DF' },
    { name: 'Espirito Santo', code: 'BR-ES' },
    { name: 'Goiás', code: 'BR-GO' },
    { name: 'Maranhão', code: 'BR-MA' },
    { name: 'Mato Grosso', code: 'BR-MT' },
    { name: 'Mato Grosso do Sul', code: 'BR-MS' },
    { name: 'Minas Gerais', code: 'BR-MG' },
    { name: 'Pará', code: 'BR-PA' },
    { name: 'Paraíba', code: 'BR-PB' },
    { name: 'Paraná', code: 'BR-PR' },
    { name: 'Pernambuco', code: 'BR-PE' },
    { name: 'Piauí', code: 'BR-PI' },
    { name: 'Rio de Janeiro', code: 'BR-RJ' },
    { name: 'Rio Grande do Norte', code: 'BR-RN' },
    { name: 'Rio Grande do Sul', code: 'BR-RS' },
    { name: 'Rondônia', code: 'BR-RO' },
    { name: 'Roraima', code: 'BR-RR' },
    { name: 'Santa Catarina', code: 'BR-SC' },
    { name: 'São Paulo', code: 'BR-SP' },
    { name: 'Sergipe', code: 'BR-SE' },
    { name: 'Tocantins', code: 'BR-TO' },
  ],
  Canada: [
    { name: 'Alberta', code: 'CA-AB' },
    { name: 'British Columbia', code: 'CA-BC' },
    { name: 'Manitoba', code: 'CA-MB' },
    { name: 'New Brunswick', code: 'CA-NB' },
    { name: 'Newfoundland and Labrador', code: 'CA-NL' },
    { name: 'Nova Scotia', code: 'CA-NS' },
    { name: 'Ontario', code: 'CA-ON' },
    { name: 'Prince Edward Island', code: 'CA-PE' },
    { name: 'Quebec', code: 'CA-QC' },
    { name: 'Saskatchewan', code: 'CA-SK' },
    { name: 'Yukon', code: 'CA-YT' },
    { name: 'Northwest Territories', code: 'CA-NT' },
    { name: 'Nunavut', code: 'CA-NU' },
  ],
  Chile: [
    { name: 'Arica y Parinacota', code: 'CL-AP' },
    { name: 'Tarapacá', code: 'CL-TA' },
    { name: 'Antofagasta', code: 'CL-AN' },
    { name: 'Atacama', code: 'CL-AT' },
    { name: 'Coquimbo', code: 'CL-CO' },
    { name: 'Valparaíso', code: 'CL-VS' },
    { name: 'Santiago', code: 'CL-RM' },
    { name: "Libertador General Bernardo O'Higgins", code: 'CL-LI' },
    { name: 'Maule', code: 'CL-ML' },
    { name: 'Biobío', code: 'CL-BI' },
    { name: 'La Araucanía', code: 'CL-AR' },
    { name: 'Los Lagos', code: 'CL-LL' },
    { name: 'Aysén del General Carlos Ibáñez del Campo', code: 'CL-AI' },
    { name: 'Magallanes y la Antártica Chilena', code: 'CL-MA' },
  ],
  China: [
    { name: 'Anhui', code: 'CN-AH' },
    { name: 'Fujian', code: 'CN-FJ' },
    { name: 'Gansu', code: 'CN-GS' },
    { name: 'Guangdong', code: 'CN-GD' },
    { name: 'Guizhou', code: 'CN-GZ' },
    { name: 'Hainan', code: 'CN-HI' },
    { name: 'Hebei', code: 'CN-HE' },
    { name: 'Heilongjiang', code: 'CN-HL' },
    { name: 'Henan', code: 'CN-HA' },
    { name: 'Hubei', code: 'CN-HB' },
    { name: 'Hunan', code: 'CN-HN' },
    { name: 'Jiangsu', code: 'CN-JS' },
    { name: 'Jiangxi', code: 'CN-JX' },
    { name: 'Jilin', code: 'CN-JL' },
    { name: 'Liaoning', code: 'CN-LN' },
    { name: 'Qinghai', code: 'CN-QH' },
    { name: 'Shaanxi', code: 'CN-SN' },
    { name: 'Shandong', code: 'CN-SD' },
    { name: 'Shanghai', code: 'CN-SH' },
    { name: 'Shanxi', code: 'CN-SX' },
    { name: 'Sichuan', code: 'CN-SC' },
    { name: 'Tianjin', code: 'CN-TJ' },
    { name: 'Xinjiang', code: 'CN-XJ' },
    { name: 'Yunnan', code: 'CN-YN' },
    { name: 'Zhejiang', code: 'CN-ZJ' },
  ],
  Colombia: [
    { name: 'Amazonas', code: 'CO-AMA' },
    { name: 'Antioquia', code: 'CO-ANT' },
    { name: 'Arauca', code: 'CO-ARA' },
    { name: 'Atlántico', code: 'CO-ATL' },
    { name: 'Bolívar', code: 'CO-BOL' },
    { name: 'Boyacá', code: 'CO-BOY' },
    { name: 'Caldas', code: 'CO-CAL' },
    { name: 'Caquetá', code: 'CO-CAQ' },
    { name: 'Casanare', code: 'CO-CAS' },
    { name: 'Cauca', code: 'CO-CAU' },
    { name: 'Cesar', code: 'CO-CES' },
    { name: 'Chocó', code: 'CO-CHO' },
    { name: 'Córdoba', code: 'CO-COR' },
    { name: 'Cundinamarca', code: 'CO-CUN' },
    { name: 'Guainía', code: 'CO-GUA' },
    { name: 'Guaviare', code: 'CO-GUV' },
    { name: 'Huila', code: 'CO-HUI' },
    { name: 'La Guajira', code: 'CO-LAG' },
    { name: 'Magdalena', code: 'CO-MAG' },
    { name: 'Meta', code: 'CO-MET' },
    { name: 'Nariño', code: 'CO-NAR' },
    { name: 'Norte de Santander', code: 'CO-NSA' },
    { name: 'Putumayo', code: 'CO-PUT' },
    { name: 'Quindío', code: 'CO-QUI' },
    { name: 'Risaralda', code: 'CO-RIS' },
    { name: 'Santander', code: 'CO-SAN' },
    { name: 'Sucre', code: 'CO-SUC' },
    { name: 'Tolima', code: 'CO-TOL' },
    { name: 'Valle del Cauca', code: 'CO-VAC' },
    { name: 'Vaupés', code: 'CO-VAU' },
    { name: 'Vichada', code: 'CO-VID' },
  ],
  Denmark: [
    { name: 'Capital Region of Denmark', code: 'DK-84' },
    { name: 'Central Denmark Region', code: 'DK-82' },
    { name: 'North Denmark Region', code: 'DK-81' },
    { name: 'Region Zealand', code: 'DK-85' },
    { name: 'Region of Southern Denmark', code: 'DK-83' },
  ],
  Egypt: [
    { name: 'Alexandria', code: 'EG-ALX' },
    { name: 'Aswan', code: 'EG-ASN' },
    { name: 'Asyut', code: 'EG-AST' },
    { name: 'Beheira', code: 'EG-BH' },
    { name: 'Beni Suef', code: 'EG-BNS' },
    { name: 'Cairo', code: 'EG-C' },
    { name: 'Dakahlia', code: 'EG-DK' },
    { name: 'Damietta', code: 'EG-DT' },
    { name: 'Faiyum', code: 'EG-FYM' },
    { name: 'Gharbia', code: 'EG-GH' },
    { name: 'Giza', code: 'EG-GZ' },
    { name: 'Ismailia', code: 'EG-IS' },
    { name: 'Kafr el-Sheikh', code: 'EG-KFS' },
    { name: 'Luxor', code: 'EG-LX' },
    { name: 'Matruh', code: 'EG-MT' },
    { name: 'Minya', code: 'EG-MN' },
    { name: 'Monufia', code: 'EG-MNF' },
    { name: 'New Valley', code: 'EG-WAD' },
    { name: 'North Sinai', code: 'EG-SIN' },
    { name: 'Port Said', code: 'EG-PTS' },
    { name: 'Qalyubia', code: 'EG-KB' },
    { name: 'Qena', code: 'EG-KN' },
    { name: 'Red Sea', code: 'EG-BA' },
    { name: 'Sharqia', code: 'EG-SHR' },
    { name: 'Sohag', code: 'EG-SHG' },
    { name: 'South Sinai', code: 'EG-JS' },
    { name: 'Suez', code: 'EG-SUZ' },
  ],
  Finland: [
    { name: 'Åland Islands', code: 'FI-01' },
    { name: 'Central Finland', code: 'FI-08' },
    { name: 'Central Ostrobothnia', code: 'FI-07' },
    { name: 'Finland Proper', code: 'FI-19' },
    { name: 'Kainuu', code: 'FI-05' },
    { name: 'Kymenlaakso', code: 'FI-09' },
    { name: 'Lapland', code: 'FI-10' },
    { name: 'North Karelia', code: 'FI-13' },
    { name: 'Northern Ostrobothnia', code: 'FI-14' },
    { name: 'Northern Savonia', code: 'FI-15' },
    { name: 'Ostrobothnia', code: 'FI-12' },
    { name: 'Päijänne-Tavastia', code: 'FI-16' },
    { name: 'Pirkanmaa', code: 'FI-11' },
    { name: 'Satakunta', code: 'FI-17' },
    { name: 'South Karelia', code: 'FI-02' },
    { name: 'South Ostrobothnia', code: 'FI-03' },
    { name: 'South Savonia', code: 'FI-04' },
    { name: 'Uusimaa', code: 'FI-18' },
  ],
  France: [
    { name: 'Auvergne-Rhône-Alpes', code: 'FR-ARA' },
    { name: 'Bourgogne-Franche-Comté', code: 'FR-BFC' },
    { name: 'Bretagne', code: 'FR-BRE' },
    { name: 'Centre-Val de Loire', code: 'FR-CVL' },
    { name: 'Corse', code: 'FR-COR' },
    { name: 'Grand Est', code: 'FR-GES' },
    { name: 'Hauts-de-France', code: 'FR-HDF' },
    { name: 'Île-de-France', code: 'FR-IDF' },
    { name: 'Normandie', code: 'FR-NOR' },
    { name: 'Nouvelle-Aquitaine', code: 'FR-NAQ' },
    { name: 'Occitanie', code: 'FR-OCC' },
    { name: 'Pays de la Loire', code: 'FR-PDL' },
    { name: "Provence-Alpes-Côte d'Azur", code: 'FR-PAC' },
  ],
  Germany: [
    { name: 'Baden-Württemberg', code: 'DE-BW' },
    { name: 'Bavaria', code: 'DE-BY' },
    { name: 'Berlin', code: 'DE-BE' },
    { name: 'Brandenburg', code: 'DE-BB' },
    { name: 'Bremen', code: 'DE-HB' },
    { name: 'Hamburg', code: 'DE-HH' },
    { name: 'Hesse', code: 'DE-HE' },
    { name: 'Mecklenburg-Vorpommern', code: 'DE-MV' },
    { name: 'Lower Saxony', code: 'DE-NI' },
    { name: 'North Rhine-Westphalia', code: 'DE-NW' },
    { name: 'Rhineland-Palatinate', code: 'DE-RP' },
    { name: 'Saarland', code: 'DE-SL' },
    { name: 'Saxony', code: 'DE-SN' },
    { name: 'Saxony-Anhalt', code: 'DE-ST' },
    { name: 'Schleswig-Holstein', code: 'DE-SH' },
    { name: 'Thuringia', code: 'DE-TH' },
  ],
  Greece: [
    { name: 'Attica', code: 'GR-AT' },
    { name: 'Central Greece', code: 'GR-07' },
    { name: 'Central Macedonia', code: 'GR-03' },
    { name: 'Crete', code: 'GR-94' },
    { name: 'Eastern Macedonia and Thrace', code: 'GR-02' },
    { name: 'Epirus', code: 'GR-05' },
    { name: 'Ionian Islands', code: 'GR-21' },
    { name: 'North Aegean', code: 'GR-81' },
    { name: 'Peloponnese', code: 'GR-15' },
    { name: 'South Aegean', code: 'GR-82' },
    { name: 'Thessaly', code: 'GR-14' },
    { name: 'Western Greece', code: 'GR-13' },
    { name: 'Western Macedonia', code: 'GR-01' },
  ],
  Hungary: [
    { name: 'Baranya', code: 'HU-BA' },
    { name: 'Bács-Kiskun', code: 'HU-BK' },
    { name: 'Békés', code: 'HU-BE' },
    { name: 'Borsod-Abaúj-Zemplén', code: 'HU-BZ' },
    { name: 'Budapest', code: 'HU-BU' },
    { name: 'Csongrád-Csanád', code: 'HU-CS' },
    { name: 'Fejér', code: 'HU-FE' },
    { name: 'Győr-Moson-Sopron', code: 'HU-GS' },
    { name: 'Hajdú-Bihar', code: 'HU-HB' },
    { name: 'Heves', code: 'HU-HE' },
    { name: 'Jász-Nagykun-Szolnok', code: 'HU-JN' },
    { name: 'Komárom-Esztergom', code: 'HU-KE' },
    { name: 'Nógrád', code: 'HU-NO' },
    { name: 'Pest', code: 'HU-PE' },
    { name: 'Somogy', code: 'HU-SO' },
    { name: 'Szabolcs-Szatmár-Bereg', code: 'HU-SZ' },
    { name: 'Tolna', code: 'HU-TO' },
    { name: 'Vas', code: 'HU-VA' },
    { name: 'Veszprém', code: 'HU-VE' },
    { name: 'Zala', code: 'HU-ZA' },
  ],
  Iceland: [
    { name: 'Austurland', code: 'IS-7' },
    { name: 'Höfuðborgarsvæði', code: 'IS-1' },
    { name: 'Norðurland eystra', code: 'IS-6' },
    { name: 'Norðurland vestra', code: 'IS-5' },
    { name: 'Suðurland', code: 'IS-8' },
    { name: 'Suðurnes', code: 'IS-2' },
    { name: 'Vestfirðir', code: 'IS-4' },
    { name: 'Vesturland', code: 'IS-3' },
  ],
  India: [
    { name: 'Andhra Pradesh', code: 'IN-AP' },
    { name: 'Arunachal Pradesh', code: 'IN-AR' },
    { name: 'Assam', code: 'IN-AS' },
    { name: 'Bihar', code: 'IN-BR' },
    { name: 'Chhattisgarh', code: 'IN-CT' },
    { name: 'Goa', code: 'IN-GA' },
    { name: 'Gujarat', code: 'IN-GJ' },
    { name: 'Haryana', code: 'IN-HR' },
    { name: 'Himachal Pradesh', code: 'IN-HP' },
    { name: 'Jammu and Kashmir', code: 'IN-JK' },
    { name: 'Jharkhand', code: 'IN-JH' },
    { name: 'Karnataka', code: 'IN-KA' },
    { name: 'Kerala', code: 'IN-KL' },
    { name: 'Madhya Pradesh', code: 'IN-MP' },
    { name: 'Maharashtra', code: 'IN-MH' },
    { name: 'Manipur', code: 'IN-MN' },
    { name: 'Meghalaya', code: 'IN-ML' },
    { name: 'Mizoram', code: 'IN-MZ' },
    { name: 'Nagaland', code: 'IN-NL' },
    { name: 'Odisha', code: 'IN-OR' },
    { name: 'Punjab', code: 'IN-PB' },
    { name: 'Rajasthan', code: 'IN-RJ' },
    { name: 'Sikkim', code: 'IN-SK' },
    { name: 'Tamil Nadu', code: 'IN-TN' },
    { name: 'Telangana', code: 'IN-TG' },
    { name: 'Tripura', code: 'IN-TR' },
    { name: 'Uttarakhand', code: 'IN-UT' },
    { name: 'Uttar Pradesh', code: 'IN-UP' },
    { name: 'West Bengal', code: 'IN-WB' },
  ],
  Indonesia: [
    { name: 'Aceh', code: 'ID-AC' },
    { name: 'Bali', code: 'ID-BA' },
    { name: 'Banten', code: 'ID-BT' },
    { name: 'Bengkulu', code: 'ID-BE' },
    { name: 'Central Java', code: 'ID-JT' },
    { name: 'Central Kalimantan', code: 'ID-KT' },
    { name: 'Central Sulawesi', code: 'ID-ST' },
    { name: 'East Java', code: 'ID-JI' },
    { name: 'East Kalimantan', code: 'ID-KI' },
    { name: 'East Nusa Tenggara', code: 'ID-NT' },
    { name: 'East Sulawesi', code: 'ID-SG' },
    { name: 'Gorontalo', code: 'ID-GO' },
    { name: 'Jambi', code: 'ID-JA' },
    { name: 'Lampung', code: 'ID-LA' },
    { name: 'Maluku', code: 'ID-MA' },
    { name: 'North Kalimantan', code: 'ID-KU' },
    { name: 'North Maluku', code: 'ID-MU' },
    { name: 'North Sulawesi', code: 'ID-SA' },
    { name: 'North Sumatra', code: 'ID-SU' },
    { name: 'Papua', code: 'ID-PA' },
    { name: 'Riau', code: 'ID-RI' },
    { name: 'Riau Islands', code: 'ID-KR' },
    { name: 'South Kalimantan', code: 'ID-KS' },
    { name: 'South Sulawesi', code: 'ID-SN' },
    { name: 'South Sumatra', code: 'ID-SS' },
    { name: 'Special Capital Region of Jakarta', code: 'ID-JK' },
    { name: 'Sulawesi', code: 'ID-SL' },
    { name: 'West Java', code: 'ID-JB' },
    { name: 'West Kalimantan', code: 'ID-KB' },
    { name: 'West Nusa Tenggara', code: 'ID-NB' },
    { name: 'West Papua', code: 'ID-PB' },
    { name: 'West Sulawesi', code: 'ID-SR' },
    { name: 'West Sumatra', code: 'ID-SB' },
    { name: 'Yogyakarta', code: 'ID-YO' },
  ],
  Iran: [
    { name: 'Alborz', code: 'IR-32' },
    { name: 'Ardabil', code: 'IR-03' },
    { name: 'Bushehr', code: 'IR-06' },
    { name: 'Chaharmahal and Bakhtiari', code: 'IR-08' },
    { name: 'East Azerbaijan', code: 'IR-01' },
    { name: 'Fars', code: 'IR-14' },
    { name: 'Gilan', code: 'IR-19' },
    { name: 'Golestan', code: 'IR-27' },
    { name: 'Hamadan', code: 'IR-24' },
    { name: 'Hormozgan', code: 'IR-23' },
    { name: 'Ilam', code: 'IR-16' },
    { name: 'Isfahan', code: 'IR-04' },
    { name: 'Kerman', code: 'IR-15' },
    { name: 'Kermanshah', code: 'IR-05' },
    { name: 'Khuzestan', code: 'IR-10' },
    { name: 'Kohgiluyeh and Boyer-Ahmad', code: 'IR-17' },
    { name: 'Kurdistan', code: 'IR-12' },
    { name: 'Lorestan', code: 'IR-20' },
    { name: 'Markazi', code: 'IR-22' },
    { name: 'Mazandaran', code: 'IR-02' },
    { name: 'North Khorasan', code: 'IR-31' },
    { name: 'Qazvin', code: 'IR-28' },
    { name: 'Qom', code: 'IR-25' },
    { name: 'Razavi Khorasan', code: 'IR-30' },
    { name: 'Semnan', code: 'IR-20' },
    { name: 'Sistan and Baluchestan', code: 'IR-13' },
    { name: 'South Khorasan', code: 'IR-29' },
    { name: 'Tehran', code: 'IR-23' },
    { name: 'West Azerbaijan', code: 'IR-02' },
    { name: 'Yazd', code: 'IR-21' },
    { name: 'Zanjan', code: 'IR-19' },
  ],
  Iraq: [
    { name: 'Al Anbar', code: 'IQ-AN' },
    { name: 'Al Basrah', code: 'IQ-BA' },
    { name: 'Al Muthanna', code: 'IQ-MU' },
    { name: 'Al Qadisiyah', code: 'IQ-QA' },
    { name: 'An Najaf', code: 'IQ-NA' },
    { name: 'Arbil', code: 'IQ-AR' },
    { name: 'As Sulaymaniyah', code: 'IQ-SU' },
    { name: 'Babil', code: 'IQ-BB' },
    { name: 'Baghdad', code: 'IQ-BG' },
    { name: 'Dahuk', code: 'IQ-DA' },
    { name: 'Dhi Qar', code: 'IQ-DQ' },
    { name: 'Diyala', code: 'IQ-DI' },
    { name: 'Karbala', code: 'IQ-KA' },
    { name: 'Kirkuk', code: 'IQ-KI' },
    { name: 'Maysan', code: 'IQ-MA' },
    { name: 'Ninawa', code: 'IQ-NI' },
    { name: 'Salah ad Din', code: 'IQ-SD' },
    { name: 'Wasit', code: 'IQ-WA' },
  ],
  Ireland: [
    { name: 'Carlow', code: 'IE-CW' },
    { name: 'Cavan', code: 'IE-CN' },
    { name: 'Clare', code: 'IE-CE' },
    { name: 'Cork', code: 'IE-CO' },
    { name: 'Donegal', code: 'IE-DL' },
    { name: 'Dublin', code: 'IE-D' },
    { name: 'Galway', code: 'IE-G' },
    { name: 'Kerry', code: 'IE-KE' },
    { name: 'Kildare', code: 'IE-KE' },
    { name: 'Kilkenny', code: 'IE-KK' },
    { name: 'Laois', code: 'IE-LS' },
    { name: 'Leitrim', code: 'IE-LM' },
    { name: 'Limerick', code: 'IE-LK' },
    { name: 'Longford', code: 'IE-LD' },
    { name: 'Louth', code: 'IE-LH' },
    { name: 'Mayo', code: 'IE-MO' },
    { name: 'Meath', code: 'IE-MH' },
    { name: 'Monaghan', code: 'IE-MN' },
    { name: 'Offaly', code: 'IE-OY' },
    { name: 'Roscommon', code: 'IE-RN' },
    { name: 'Sligo', code: 'IE-SO' },
    { name: 'Tipperary', code: 'IE-TA' },
    { name: 'Waterford', code: 'IE-WD' },
    { name: 'Westmeath', code: 'IE-WH' },
    { name: 'Wexford', code: 'IE-WX' },
    { name: 'Wicklow', code: 'IE-WW' },
  ],
  Israel: [
    { name: 'Central District', code: 'IL-M' },
    { name: 'Haifa District', code: 'IL-HA' },
    { name: 'Jerusalem District', code: 'IL-JM' },
    { name: 'Northern District', code: 'IL-Z' },
    { name: 'Southern District', code: 'IL-D' },
    { name: 'Tel Aviv District', code: 'IL-TA' },
  ],
  Italy: [
    { name: 'Abruzzo', code: 'IT-65' },
    { name: 'Aosta Valley', code: 'IT-23' },
    { name: 'Apulia', code: 'IT-75' },
    { name: 'Basilicata', code: 'IT-77' },
    { name: 'Calabria', code: 'IT-78' },
    { name: 'Campania', code: 'IT-72' },
    { name: 'Emilia-Romagna', code: 'IT-45' },
    { name: 'Friuli-Venezia Giulia', code: 'IT-36' },
    { name: 'Lazio', code: 'IT-62' },
    { name: 'Liguria', code: 'IT-42' },
    { name: 'Lombardy', code: 'IT-25' },
    { name: 'Marche', code: 'IT-57' },
    { name: 'Molise', code: 'IT-67' },
    { name: 'Piedmont', code: 'IT-21' },
    { name: 'Sardinia', code: 'IT-88' },
    { name: 'Sicily', code: 'IT-82' },
    { name: 'Tuscany', code: 'IT-52' },
    { name: 'Trentino-Alto Adige/Südtirol', code: 'IT-32' },
    { name: 'Umbria', code: 'IT-55' },
    { name: 'Veneto', code: 'IT-34' },
  ],
  Jamaica: [
    { name: 'Hanover', code: 'JM-08' },
    { name: 'Kingston', code: 'JM-01' },
    { name: 'Manchester', code: 'JM-12' },
    { name: 'Portland', code: 'JM-04' },
    { name: 'Saint Andrew', code: 'JM-02' },
    { name: 'Saint Ann', code: 'JM-06' },
    { name: 'Saint Catherine', code: 'JM-14' },
    { name: 'Saint Elizabeth', code: 'JM-11' },
    { name: 'Saint James', code: 'JM-07' },
    { name: 'Saint Mary', code: 'JM-05' },
    { name: 'Saint Thomas', code: 'JM-03' },
    { name: 'Trelawny', code: 'JM-13' },
    { name: 'Westmoreland', code: 'JM-10' },
  ],
  Japan: [
    { name: 'Aichi', code: 'JP-23' },
    { name: 'Akita', code: 'JP-05' },
    { name: 'Aomori', code: 'JP-02' },
    { name: 'Chiba', code: 'JP-12' },
    { name: 'Ehime', code: 'JP-38' },
    { name: 'Fukui', code: 'JP-18' },
    { name: 'Fukuoka', code: 'JP-40' },
    { name: 'Fukushima', code: 'JP-07' },
    { name: 'Gifu', code: 'JP-21' },
    { name: 'Gunma', code: 'JP-10' },
    { name: 'Hiroshima', code: 'JP-34' },
    { name: 'Hokkaido', code: 'JP-01' },
    { name: 'Hyogo', code: 'JP-28' },
    { name: 'Ibaraki', code: 'JP-08' },
    { name: 'Ishikawa', code: 'JP-17' },
    { name: 'Iwate', code: 'JP-03' },
    { name: 'Kagawa', code: 'JP-37' },
    { name: 'Kagoshima', code: 'JP-46' },
    { name: 'Kanagawa', code: 'JP-14' },
    { name: 'Kochi', code: 'JP-39' },
    { name: 'Kumamoto', code: 'JP-43' },
    { name: 'Kyoto', code: 'JP-26' },
    { name: 'Mie', code: 'JP-24' },
    { name: 'Miyagi', code: 'JP-04' },
    { name: 'Miyazaki', code: 'JP-45' },
    { name: 'Nagano', code: 'JP-20' },
    { name: 'Nagasaki', code: 'JP-42' },
    { name: 'Nara', code: 'JP-29' },
    { name: 'Niigata', code: 'JP-15' },
    { name: 'Oita', code: 'JP-44' },
    { name: 'Okayama', code: 'JP-33' },
    { name: 'Okinawa', code: 'JP-47' },
    { name: 'Osaka', code: 'JP-27' },
    { name: 'Saga', code: 'JP-41' },
    { name: 'Saitama', code: 'JP-11' },
    { name: 'Shiga', code: 'JP-25' },
    { name: 'Shimane', code: 'JP-32' },
    { name: 'Shizuoka', code: 'JP-22' },
    { name: 'Tochigi', code: 'JP-09' },
    { name: 'Tokushima', code: 'JP-36' },
    { name: 'Tokyo', code: 'JP-13' },
    { name: 'Tottori', code: 'JP-31' },
    { name: 'Toyama', code: 'JP-16' },
    { name: 'Wakayama', code: 'JP-30' },
    { name: 'Yamagata', code: 'JP-06' },
    { name: 'Yamaguchi', code: 'JP-35' },
    { name: 'Yamanashi', code: 'JP-19' },
  ],
  Jordan: [
    { name: 'Ajlun', code: 'JO-AJ' },
    { name: 'Amman', code: 'JO-AM' },
    { name: 'Aqaba', code: 'JO-AQ' },
    { name: 'Balqa', code: 'JO-BA' },
    { name: 'Irbid', code: 'JO-IR' },
    { name: 'Jerash', code: 'JO-JA' },
    { name: 'Karak', code: 'JO-KA' },
    { name: "Ma'an", code: 'JO-MA' },
    { name: 'Madaba', code: 'JO-MD' },
    { name: 'Mafraq', code: 'JO-MA' },
    { name: 'Tafilah', code: 'JO-TA' },
    { name: 'Zarqa', code: 'JO-ZA' },
  ],
  Kazakhstan: [
    { name: 'Abai', code: 'KZ-10' },
    { name: 'Akmola', code: 'KZ-02' },
    { name: 'Aktobe', code: 'KZ-03' },
    { name: 'Almaty', code: 'KZ-19' },
    { name: 'Atyrau', code: 'KZ-05' },
    { name: 'East Kazakhstan', code: 'KZ-16' },
    { name: 'Jambyl', code: 'KZ-08' },
    { name: 'Karaganda', code: 'KZ-09' },
    { name: 'Kostanay', code: 'KZ-06' },
    { name: 'Kyzylorda', code: 'KZ-12' },
    { name: 'Mangystau', code: 'KZ-13' },
    { name: 'Nur-Sultan', code: 'KZ-71' },
    { name: 'Pavlodar', code: 'KZ-14' },
    { name: 'North Kazakhstan', code: 'KZ-15' },
    { name: 'Turkistan', code: 'KZ-17' },
    { name: 'Ulytau', code: 'KZ-21' },
    { name: 'West Kazakhstan', code: 'KZ-27' },
    { name: 'Zhambyl', code: 'KZ-08' },
    { name: 'Zhetysu', code: 'KZ-18' },
  ],
  Kenya: [
    { name: 'Baringo', code: 'KE-01' },
    { name: 'Bomet', code: 'KE-02' },
    { name: 'Bungoma', code: 'KE-03' },
    { name: 'Busia', code: 'KE-04' },
    { name: 'Elgeyo-Marakwet', code: 'KE-05' },
    { name: 'Embu', code: 'KE-06' },
    { name: 'Garissa', code: 'KE-07' },
    { name: 'Homa Bay', code: 'KE-08' },
    { name: 'Isiolo', code: 'KE-09' },
    { name: 'Kajiado', code: 'KE-10' },
    { name: 'Kakamega', code: 'KE-11' },
    { name: 'Kericho', code: 'KE-12' },
    { name: 'Kiambu', code: 'KE-13' },
    { name: 'Kilifi', code: 'KE-14' },
    { name: 'Kirinyaga', code: 'KE-15' },
    { name: 'Kisii', code: 'KE-16' },
    { name: 'Kisumu', code: 'KE-17' },
    { name: 'Kitui', code: 'KE-18' },
    { name: 'Kwale', code: 'KE-19' },
    { name: 'Laikipia', code: 'KE-20' },
    { name: 'Lamu', code: 'KE-21' },
    { name: 'Machakos', code: 'KE-22' },
    { name: 'Makueni', code: 'KE-23' },
    { name: 'Mandera', code: 'KE-24' },
    { name: 'Marsabit', code: 'KE-25' },
    { name: 'Meru', code: 'KE-26' },
    { name: 'Migori', code: 'KE-27' },
    { name: 'Mombasa', code: 'KE-28' },
    { name: "Murang'a", code: 'KE-29' },
    { name: 'Nairobi', code: 'KE-30' },
    { name: 'Nakuru', code: 'KE-31' },
    { name: 'Nandi', code: 'KE-32' },
    { name: 'Narok', code: 'KE-33' },
    { name: 'Nyamira', code: 'KE-34' },
    { name: 'Nyandarua', code: 'KE-35' },
    { name: 'Nyeri', code: 'KE-36' },
    { name: 'Samburu', code: 'KE-37' },
    { name: 'Siaya', code: 'KE-38' },
    { name: 'Taita-Taveta', code: 'KE-39' },
    { name: 'Tana River', code: 'KE-40' },
    { name: 'Tharaka-Nithi', code: 'KE-41' },
    { name: 'Trans Nzoia', code: 'KE-42' },
    { name: 'Turkana', code: 'KE-43' },
    { name: 'Uasin Gishu', code: 'KE-44' },
    { name: 'Vihiga', code: 'KE-45' },
    { name: 'Wajir', code: 'KE-46' },
    { name: 'West Pokot', code: 'KE-47' },
  ],
  Kiribati: [
    { name: 'Abaiang', code: 'KI-01' },
    { name: 'Abemama', code: 'KI-02' },
    { name: 'Aranuka', code: 'KI-03' },
    { name: 'Arorae', code: 'KI-04' },
    { name: 'Banaba', code: 'KI-05' },
    { name: 'Beru', code: 'KI-06' },
    { name: 'Butaritari', code: 'KI-07' },
    { name: 'Kiritimati', code: 'KI-08' },
    { name: 'Kuria', code: 'KI-09' },
    { name: 'Maiana', code: 'KI-10' },
    { name: 'Makin', code: 'KI-11' },
    { name: 'Marakei', code: 'KI-12' },
    { name: 'Nikunau', code: 'KI-13' },
    { name: 'Nonouti', code: 'KI-14' },
    { name: 'Onotoa', code: 'KI-15' },
    { name: 'Tabiteuea North', code: 'KI-16' },
    { name: 'Tabiteuea South', code: 'KI-17' },
    { name: 'Tamana', code: 'KI-18' },
    { name: 'Tarawa North', code: 'KI-19' },
    { name: 'Tarawa South', code: 'KI-20' },
    { name: 'Teinainano Urban Council', code: 'KI-21' },
  ],
  'North Korea': [
    { name: 'Chagang', code: 'KP-04' },
    { name: 'Hamgyong North', code: 'KP-09' },
    { name: 'Hamgyong South', code: 'KP-08' },
    { name: 'Kangwon', code: 'KP-07' },
    { name: 'North Hwanghae', code: 'KP-06' },
    { name: 'North Pyongan', code: 'KP-03' },
    { name: 'Pyongyang', code: 'KP-01' },
    { name: 'Rason', code: 'KP-13' },
    { name: 'Ryanggang', code: 'KP-10' },
    { name: 'South Hwanghae', code: 'KP-05' },
    { name: 'South Pyongan', code: 'KP-02' },
  ],
  'North Macedonia': [
    { name: 'Aerodrom', code: 'MK-01' },
    { name: 'Aračinovo', code: 'MK-02' },
    { name: 'Berovo', code: 'MK-03' },
    { name: 'Bitola', code: 'MK-04' },
    { name: 'Bogdanci', code: 'MK-05' },
    { name: 'Bogovinje', code: 'MK-06' },
    { name: 'Bosilovo', code: 'MK-07' },
    { name: 'Brvenica', code: 'MK-08' },
    { name: 'Butel', code: 'MK-09' },
    { name: 'Čaška', code: 'MK-16' },
    { name: 'Češinovo-Obleševo', code: 'MK-21' },
    { name: 'Centar Župa', code: 'MK-17' },
    { name: 'Debar', code: 'MK-22' },
    { name: 'Debarca', code: 'MK-23' },
    { name: 'Delčevo', code: 'MK-24' },
    { name: 'Demir Hisar', code: 'MK-25' },
    { name: 'Demir Kapija', code: 'MK-26' },
    { name: 'Dojran', code: 'MK-28' },
    { name: 'Dolneni', code: 'MK-29' },
    { name: 'Drugovo', code: 'MK-30' },
    { name: 'Gevgelija', code: 'MK-31' },
    { name: 'Gostivar', code: 'MK-32' },
    { name: 'Gradsko', code: 'MK-34' },
    { name: 'Ilinden', code: 'MK-35' },
    { name: 'Jegunovce', code: 'MK-36' },
    { name: 'Kavadarci', code: 'MK-37' },
    { name: 'Kičevo', code: 'MK-38' },
    { name: 'Kočani', code: 'MK-40' },
    { name: 'Konče', code: 'MK-41' },
    { name: 'Kratovo', code: 'MK-42' },
    { name: 'Kriva Palanka', code: 'MK-43' },
    { name: 'Kruševo', code: 'MK-44' },
    { name: 'Kumanovo', code: 'MK-45' },
    { name: 'Lipkovo', code: 'MK-46' },
    { name: 'Lozovo', code: 'MK-47' },
    { name: 'Makedonska Kamenica', code: 'MK-49' },
    { name: 'Makedonski Brod', code: 'MK-50' },
    { name: 'Mogila', code: 'MK-51' },
    { name: 'Negotino', code: 'MK-52' },
    { name: 'Novaci', code: 'MK-53' },
    { name: 'Novo Selo', code: 'MK-54' },
    { name: 'Ohrid', code: 'MK-55' },
    { name: 'Pehčevo', code: 'MK-56' },
    { name: 'Petrovec', code: 'MK-57' },
    { name: 'Plasnica', code: 'MK-58' },
    { name: 'Prilep', code: 'MK-60' },
    { name: 'Probištip', code: 'MK-61' },
    { name: 'Radoviš', code: 'MK-62' },
    { name: 'Rankovce', code: 'MK-63' },
    { name: 'Resen', code: 'MK-64' },
    { name: 'Rosoman', code: 'MK-65' },
    { name: 'Saraj', code: 'MK-66' },
    { name: 'Sopište', code: 'MK-67' },
    { name: 'Staro Nagoričane', code: 'MK-68' },
    { name: 'Stip', code: 'MK-70' },
    { name: 'Struga', code: 'MK-71' },
    { name: 'Strumica', code: 'MK-72' },
    { name: 'Studeničani', code: 'MK-73' },
    { name: 'Štip', code: 'MK-75' },
    { name: 'Šuto Orizari', code: 'MK-10' },
    { name: 'Tearce', code: 'MK-76' },
    { name: 'Tetovo', code: 'MK-78' },
    { name: 'Valandovo', code: 'MK-80' },
    { name: 'Vasilevo', code: 'MK-81' },
    { name: 'Veles', code: 'MK-82' },
    { name: 'Vevčani', code: 'MK-83' },
    { name: 'Vinica', code: 'MK-84' },
    { name: 'Vraneštica', code: 'MK-85' },
    { name: 'Vrapčište', code: 'MK-86' },
    { name: 'Zajas', code: 'MK-87' },
    { name: 'Želino', code: 'MK-88' },
    { name: 'Žeškovci', code: 'MK-89' },
    { name: 'Žitiše', code: 'MK-90' },
  ],
  Kosovo: [
    { name: 'Deçan', code: 'XK-03' },
    { name: 'Ferizaj', code: 'XK-05' },
    { name: 'Fushë Kosovë', code: 'XK-01' },
    { name: 'Gjakovë', code: 'XK-04' },
    { name: 'Gjilan', code: 'XK-06' },
    { name: 'Istog', code: 'XK-03' },
    { name: 'Kamëz', code: 'XK-02' },
    { name: 'Kaçanik', code: 'XK-04' },
    { name: 'Klinë', code: 'XK-05' },
    { name: 'Kosovska Kamenica', code: 'XK-25' },
    { name: 'Kosovska Mitrovica', code: 'XK-28' },
    { name: 'Leposavić', code: 'XK-35' },
    { name: 'Lipljan', code: 'XK-37' },
    { name: 'Malishevë', code: 'XK-39' },
    { name: 'Mamushë', code: 'XK-43' },
    { name: 'Mitrovica', code: 'XK-28' },
    { name: 'Novo Brdo', code: 'XK-45' },
    { name: 'Obilić', code: 'XK-48' },
    { name: 'Orahovac', code: 'XK-50' },
    { name: 'Pejë', code: 'XK-52' },
    { name: 'Podujevë', code: 'XK-55' },
    { name: 'Pristina', code: 'XK-01' },
    { name: 'Prizren', code: 'XK-58' },
    { name: 'Shtime', code: 'XK-61' },
    { name: 'Shtërpcë', code: 'XK-62' },
    { name: 'Skenderaj', code: 'XK-63' },
    { name: 'Suharekë', code: 'XK-65' },
    { name: 'Viti', code: 'XK-68' },
    { name: 'Vushtrri', code: 'XK-70' },
    { name: 'Zubin Potok', code: 'XK-75' },
    { name: 'Zveçan', code: 'XK-77' },
  ],
  Kuwait: [
    { name: 'Al Ahmadi', code: 'KW-AH' },
    { name: 'Al Farwaniyah', code: 'KW-FA' },
    { name: 'Al Jahra', code: 'KW-JA' },
    { name: 'Al Kuwayt', code: 'KW-KU' },
    { name: 'Hawalli', code: 'KW-HA' },
    { name: 'Mubarak Al-Kabeer', code: 'KW-MU' },
  ],
  Kyrgyzstan: [
    { name: 'Batken', code: 'KG-B' },
    { name: 'Bishkek', code: 'KG-GB' },
    { name: 'Chui', code: 'KG-C' },
    { name: 'Issyk-Kul', code: 'KG-Y' },
    { name: 'Jalal-Abad', code: 'KG-J' },
    { name: 'Naryn', code: 'KG-N' },
    { name: 'Osh', code: 'KG-O' },
    { name: 'Talas', code: 'KG-T' },
  ],
  Laos: [
    { name: 'Attapu', code: 'LA-AT' },
    { name: 'Bokèo', code: 'LA-BK' },
    { name: 'Bolikhamsai', code: 'LA-BL' },
    { name: 'Champasak', code: 'LA-CH' },
    { name: 'Houaphan', code: 'LA-HO' },
    { name: 'Khammouan', code: 'LA-KH' },
    { name: 'Louang Namtha', code: 'LA-LM' },
    { name: 'Louangphabang', code: 'LA-LP' },
    { name: 'Oudomxai', code: 'LA-OU' },
    { name: 'Phongsali', code: 'LA-PH' },
    { name: 'Saravane', code: 'LA-SV' },
    { name: 'Savannakhet', code: 'LA-SK' },
    { name: 'Vientiane', code: 'LA-VI' },
    { name: 'Vientiane Prefecture', code: 'LA-VT' },
    { name: 'Xaisomboun', code: 'LA-XA' },
    { name: 'Xekong', code: 'LA-XE' },
    { name: 'Xieng Khouang', code: 'LA-XI' },
  ],
  Latvia: [
    { name: 'Aglona', code: 'LV-001' },
    { name: 'Aizkraukle', code: 'LV-002' },
    { name: 'Aizpute', code: 'LV-003' },
    { name: 'Aknīste', code: 'LV-004' },
    { name: 'Aloja', code: 'LV-005' },
    { name: 'Alsunga', code: 'LV-006' },
    { name: 'Alūksne', code: 'LV-007' },
    { name: 'Amata', code: 'LV-008' },
    { name: 'Apes', code: 'LV-009' },
    { name: 'Auce', code: 'LV-010' },
    { name: 'Babīte', code: 'LV-010' },
    { name: 'Baldone', code: 'LV-011' },
    { name: 'Baltinava', code: 'LV-012' },
    { name: 'Balvi', code: 'LV-013' },
    { name: 'Bauska', code: 'LV-014' },
    { name: 'Beverīna', code: 'LV-015' },
    { name: 'Brocēni', code: 'LV-016' },
    { name: 'Burtnieki', code: 'LV-017' },
    { name: 'Carnikava', code: 'LV-018' },
    { name: 'Cēsis', code: 'LV-019' },
    { name: 'Cesvaine', code: 'LV-020' },
    { name: 'Cibla', code: 'LV-021' },
    { name: 'Dagda', code: 'LV-022' },
    { name: 'Daugavpils', code: 'LV-023' },
    { name: 'Dobele', code: 'LV-024' },
    { name: 'Dundaga', code: 'LV-025' },
    { name: 'Durbe', code: 'LV-026' },
    { name: 'Engure', code: 'LV-027' },
    { name: 'Ērgļi', code: 'LV-028' },
    { name: 'Garkalne', code: 'LV-029' },
    { name: 'Grobiņa', code: 'LV-030' },
    { name: 'Gulbene', code: 'LV-031' },
    { name: 'Iecava', code: 'LV-032' },
    { name: 'Ikšķile', code: 'LV-033' },
    { name: 'Ilūkste', code: 'LV-034' },
    { name: 'Inčukalns', code: 'LV-035' },
    { name: 'Jaunjelgava', code: 'LV-036' },
    { name: 'Jaunpiebalga', code: 'LV-037' },
    { name: 'Jaunpils', code: 'LV-038' },
    { name: 'Jēkabpils', code: 'LV-039' },
    { name: 'Jelgava', code: 'LV-040' },
    { name: 'Kandava', code: 'LV-041' },
    { name: 'Kārsava', code: 'LV-042' },
    { name: 'Kocēni', code: 'LV-043' },
    { name: 'Koknese', code: 'LV-044' },
    { name: 'Krāslava', code: 'LV-045' },
    { name: 'Krimulda', code: 'LV-046' },
    { name: 'Krustpils', code: 'LV-047' },
    { name: 'Kuldīga', code: 'LV-048' },
    { name: 'Lielvārde', code: 'LV-049' },
    { name: 'Liepāja', code: 'LV-050' },
    { name: 'Līgatne', code: 'LV-051' },
    { name: 'Limbaži', code: 'LV-052' },
    { name: 'Līvāni', code: 'LV-053' },
    { name: 'Lubāna', code: 'LV-054' },
    { name: 'Ludza', code: 'LV-055' },
    { name: 'Madona', code: 'LV-056' },
    { name: 'Mālpils', code: 'LV-057' },
    { name: 'Mārupe', code: 'LV-058' },
    { name: 'Mazsalaca', code: 'LV-059' },
    { name: 'Mērsrags', code: 'LV-060' },
    { name: 'Naukšēni', code: 'LV-061' },
    { name: 'Nereta', code: 'LV-062' },
    { name: 'Nīca', code: 'LV-063' },
    { name: 'Ogre', code: 'LV-064' },
    { name: 'Olaine', code: 'LV-065' },
    { name: 'Olaine', code: 'LV-065' },
    { name: 'Ozolnieki', code: 'LV-066' },
    { name: 'Pārgauja', code: 'LV-067' },
    { name: 'Pāvilosta', code: 'LV-068' },
    { name: 'Pļaviņas', code: 'LV-069' },
    { name: 'Preiļi', code: 'LV-070' },
    { name: 'Priekule', code: 'LV-071' },
    { name: 'Priekuļi', code: 'LV-072' },
    { name: 'Rauna', code: 'LV-073' },
    { name: 'Rēzekne', code: 'LV-074' },
    { name: 'Riebiņi', code: 'LV-075' },
    { name: 'Riga', code: 'LV-RIX' },
    { name: 'Ropaži', code: 'LV-077' },
    { name: 'Rucava', code: 'LV-078' },
    { name: 'Rugāji', code: 'LV-079' },
    { name: 'Rundāle', code: 'LV-080' },
    { name: 'Rūjiena', code: 'LV-081' },
    { name: 'Saldus', code: 'LV-082' },
    { name: 'Saulkrasti', code: 'LV-083' },
    { name: 'Sēja', code: 'LV-084' },
    { name: 'Sigulda', code: 'LV-085' },
    { name: 'Skrīveri', code: 'LV-086' },
    { name: 'Skrunda', code: 'LV-087' },
    { name: 'Smiltene', code: 'LV-088' },
    { name: 'Stopiņi', code: 'LV-089' },
    { name: 'Strenči', code: 'LV-090' },
    { name: 'Talsi', code: 'LV-091' },
    { name: 'Tērvete', code: 'LV-092' },
    { name: 'Tukums', code: 'LV-093' },
    { name: 'Vaiņode', code: 'LV-094' },
    { name: 'Valka', code: 'LV-095' },
    { name: 'Varakļāni', code: 'LV-096' },
    { name: 'Vārkava', code: 'LV-097' },
    { name: 'Vecpiebalga', code: 'LV-098' },
    { name: 'Vecumnieki', code: 'LV-099' },
    { name: 'Ventspils', code: 'LV-100' },
    { name: 'Viesīte', code: 'LV-101' },
    { name: 'Viļaka', code: 'LV-102' },
    { name: 'Viļāni', code: 'LV-103' },
    { name: 'Zilupe', code: 'LV-104' },
  ],
  Lebanon: [
    { name: 'Akkar', code: 'LB-AK' },
    { name: 'Baalbek-Hermel', code: 'LB-BH' },
    { name: 'Beirut', code: 'LB-BA' },
    { name: 'Beqaa', code: 'LB-BI' },
    { name: 'Keserwan-Jbeil', code: 'LB-KJ' },
    { name: 'Mount Lebanon', code: 'LB-JL' },
    { name: 'Nabatieh', code: 'LB-NA' },
    { name: 'North Lebanon', code: 'LB-AS' },
    { name: 'South Lebanon', code: 'LB-JA' },
    { name: 'Zgharta', code: 'LB-ZG' },
  ],
  Lesotho: [
    { name: 'Berea', code: 'LS-D' },
    { name: 'Butha-Buthe', code: 'LS-B' },
    { name: 'Leribe', code: 'LS-C' },
    { name: 'Mafeteng', code: 'LS-E' },
    { name: 'Maseru', code: 'LS-A' },
    { name: "Mohale's Hoek", code: 'LS-F' },
    { name: 'Mokhotlong', code: 'LS-J' },
    { name: "Qacha's Nek", code: 'LS-H' },
    { name: 'Quthing', code: 'LS-G' },
    { name: 'Thaba-Tseka', code: 'LS-K' },
  ],
  Liberia: [
    { name: 'Bomi', code: 'LR-BM' },
    { name: 'Bong', code: 'LR-BG' },
    { name: 'Gbarpolu', code: 'LR-GP' },
    { name: 'Grand Bassa', code: 'LR-GB' },
    { name: 'Grand Cape Mount', code: 'LR-CM' },
    { name: 'Grand Gedeh', code: 'LR-GG' },
    { name: 'Grand Kru', code: 'LR-GK' },
    { name: 'Lofa', code: 'LR-LO' },
    { name: 'Margibi', code: 'LR-MG' },
    { name: 'Maryland', code: 'LR-MY' },
    { name: 'Montserrado', code: 'LR-MO' },
    { name: 'Nimba', code: 'LR-NI' },
    { name: 'River Cess', code: 'LR-RI' },
    { name: 'River Gee', code: 'LR-RG' },
    { name: 'Sinoe', code: 'LR-SI' },
  ],
  Libya: [
    { name: 'Al Butnan', code: 'LY-BU' },
    { name: 'Al Jabal al Akhdar', code: 'LY-JA' },
    { name: 'Al Jabal al Gharbi', code: 'LY-JG' },
    { name: 'Al Jufrah', code: 'LY-JU' },
    { name: 'Al Kufrah', code: 'LY-KF' },
    { name: 'Al Marj', code: 'LY-MJ' },
    { name: 'Al Murgub', code: 'LY-MQ' },
    { name: 'Al Qubbah', code: 'LY-QB' },
    { name: 'Al Wahat', code: 'LY-WA' },
    { name: 'An Nuqat al Khams', code: 'LY-NQ' },
    { name: 'Az Zawiyah', code: 'LY-ZA' },
    { name: 'Benghazi', code: 'LY-BA' },
    { name: 'Darnah', code: 'LY-DR' },
    { name: 'Ghat', code: 'LY-GT' },
    { name: 'Misratah', code: 'LY-MI' },
    { name: 'Murzuq', code: 'LY-MZ' },
    { name: 'Nalut', code: 'LY-NU' },
    { name: 'Sabha', code: 'LY-SB' },
    { name: 'Sirte', code: 'LY-SR' },
    { name: 'Tripoli', code: 'LY-TB' },
    { name: 'Wadi al Hayat', code: 'LY-WH' },
    { name: 'Wadi ash Shati', code: 'LY-WS' },
  ],
  Lithuania: [
    { name: 'Alytus', code: 'LT-AL' },
    { name: 'Kaunas', code: 'LT-KU' },
    { name: 'Klaipėda', code: 'LT-KL' },
    { name: 'Marijampolė', code: 'LT-MR' },
    { name: 'Panevėžys', code: 'LT-PN' },
    { name: 'Šiauliai', code: 'LT-SA' },
    { name: 'Tauragė', code: 'LT-TA' },
    { name: 'Telšiai', code: 'LT-TE' },
    { name: 'Utena', code: 'LT-UT' },
    { name: 'Vilnius', code: 'LT-VL' },
  ],
  Luxembourg: [
    { name: 'Diekirch', code: 'LU-D' },
    { name: 'Grevenmacher', code: 'LU-G' },
    { name: 'Luxembourg', code: 'LU-L' },
    { name: 'Wiltz', code: 'LU-W' },
  ],
  'Macedonia (FYROM)': [
    { name: 'Aerodrom', code: 'MK-AD' },
    { name: 'Aračinovo', code: 'MK-AR' },
    { name: 'Berovo', code: 'MK-BR' },
    { name: 'Bitola', code: 'MK-BT' },
    { name: 'Bogdanci', code: 'MK-BG' },
    { name: 'Bogovinje', code: 'MK-BG' },
    { name: 'Bosilovo', code: 'MK-BS' },
    { name: 'Brvenica', code: 'MK-BN' },
    { name: 'Butel', code: 'MK-BU' },
    { name: 'Čaška', code: 'MK-CA' },
    { name: 'Češinovo-Obleševo', code: 'MK-CO' },
    { name: 'Centar Župa', code: 'MK-CZ' },
    { name: 'Debar', code: 'MK-DB' },
    { name: 'Debarca', code: 'MK-DA' },
    { name: 'Delčevo', code: 'MK-DE' },
    { name: 'Demir Hisar', code: 'MK-DH' },
    { name: 'Demir Kapija', code: 'MK-DK' },
    { name: 'Dojran', code: 'MK-SD' },
    { name: 'Dolneni', code: 'MK-DE' },
    { name: 'Drugovo', code: 'MK-DR' },
    { name: 'Gevgelija', code: 'MK-GE' },
    { name: 'Gostivar', code: 'MK-GT' },
    { name: 'Gradsko', code: 'MK-GR' },
    { name: 'Ilinden', code: 'MK-IL' },
    { name: 'Jegunovce', code: 'MK-JG' },
    { name: 'Kavadarci', code: 'MK-AV' },
    { name: 'Kičevo', code: 'MK-KH' },
    { name: 'Kočani', code: 'MK-OC' },
    { name: 'Konče', code: 'MK-KN' },
    { name: 'Kratovo', code: 'MK-KY' },
    { name: 'Kriva Palanka', code: 'MK-KP' },
    { name: 'Kruševo', code: 'MK-KS' },
    { name: 'Kumanovo', code: 'MK-UM' },
    { name: 'Lipkovo', code: 'MK-LI' },
    { name: 'Lozovo', code: 'MK-LO' },
    { name: 'Makedonska Kamenica', code: 'MK-MK' },
    { name: 'Makedonski Brod', code: 'MK-MD' },
    { name: 'Mogila', code: 'MK-MG' },
    { name: 'Negotino', code: 'MK-NG' },
    { name: 'Novaci', code: 'MK-NV' },
    { name: 'Novo Selo', code: 'MK-NS' },
    { name: 'Ohrid', code: 'MK-OH' },
    { name: 'Pehčevo', code: 'MK-PH' },
    { name: 'Petrovec', code: 'MK-PE' },
    { name: 'Plasnica', code: 'MK-PL' },
    { name: 'Prilep', code: 'MK-PR' },
    { name: 'Probištip', code: 'MK-PT' },
    { name: 'Radoviš', code: 'MK-RV' },
    { name: 'Rankovce', code: 'MK-RN' },
    { name: 'Resen', code: 'MK-RE' },
    { name: 'Rosoman', code: 'MK-RM' },
    { name: 'Saraj', code: 'MK-SJ' },
    { name: 'Sopište', code: 'MK-SS' },
    { name: 'Staro Nagoričane', code: 'MK-NA' },
    { name: 'Štip', code: 'MK-ST' },
    { name: 'Struga', code: 'MK-UG' },
    { name: 'Strumica', code: 'MK-RU' },
    { name: 'Studeničani', code: 'MK-SD' },
    { name: 'Sveti Nikole', code: 'MK-SL' },
    { name: 'Tearce', code: 'MK-TR' },
    { name: 'Tetovo', code: 'MK-TE' },
    { name: 'Valandovo', code: 'MK-VL' },
    { name: 'Vasilevo', code: 'MK-VA' },
    { name: 'Veles', code: 'MK-VE' },
    { name: 'Vevčani', code: 'MK-VV' },
    { name: 'Vinica', code: 'MK-NI' },
    { name: 'Vraneštica', code: 'MK-VT' },
    { name: 'Vrapčište', code: 'MK-VH' },
    { name: 'Zajas', code: 'MK-ZA' },
    { name: 'Želino', code: 'MK-ZE' },
    { name: 'Žeškovci', code: 'MK-ZK' },
    { name: 'Žitiše', code: 'MK-ZT' },
  ],
  Madagascar: [
    { name: 'Antananarivo', code: 'MG-T' },
    { name: 'Antsiranana', code: 'MG-D' },
    { name: 'Fianarantsoa', code: 'MG-F' },
    { name: 'Mahajanga', code: 'MG-M' },
    { name: 'Toamasina', code: 'MG-A' },
    { name: 'Toliara', code: 'MG-U' },
  ],
  Malawi: [
    { name: 'Balaka', code: 'MW-BA' },
    { name: 'Blantyre', code: 'MW-BL' },
    { name: 'Chikwawa', code: 'MW-CK' },
    { name: 'Chiradzulu', code: 'MW-CR' },
    { name: 'Chitipa', code: 'MW-CT' },
    { name: 'Dedza', code: 'MW-DE' },
    { name: 'Dowa', code: 'MW-DO' },
    { name: 'Karonga', code: 'MW-KR' },
    { name: 'Kasungu', code: 'MW-KS' },
    { name: 'Lilongwe', code: 'MW-LI' },
    { name: 'Machinga', code: 'MW-MH' },
    { name: 'Mangochi', code: 'MW-MG' },
    { name: 'Mchinji', code: 'MW-MC' },
    { name: 'Mulanje', code: 'MW-MU' },
    { name: 'Mwanza', code: 'MW-MW' },
    { name: 'Mzimba', code: 'MW-MZ' },
    { name: 'Neno', code: 'MW-NE' },
    { name: 'Ntcheu', code: 'MW-NT' },
    { name: 'Ntchisi', code: 'MW-NI' },
    { name: 'Phalombe', code: 'MW-PH' },
    { name: 'Rumphi', code: 'MW-RU' },
    { name: 'Salima', code: 'MW-SA' },
    { name: 'Thyolo', code: 'MW-TH' },
    { name: 'Zomba', code: 'MW-ZO' },
  ],
  Malaysia: [
    { name: 'Johor', code: 'MY-01' },
    { name: 'Kedah', code: 'MY-02' },
    { name: 'Kelantan', code: 'MY-03' },
    { name: 'Kuala Lumpur', code: 'MY-14' },
    { name: 'Labuan', code: 'MY-15' },
    { name: 'Malacca', code: 'MY-04' },
    { name: 'Negeri Sembilan', code: 'MY-05' },
    { name: 'Pahang', code: 'MY-06' },
    { name: 'Penang', code: 'MY-07' },
    { name: 'Perak', code: 'MY-08' },
    { name: 'Perlis', code: 'MY-09' },
    { name: 'Sabah', code: 'MY-12' },
    { name: 'Sarawak', code: 'MY-13' },
    { name: 'Selangor', code: 'MY-10' },
    { name: 'Terengganu', code: 'MY-11' },
  ],
  Maldives: [
    { name: 'Addu City', code: 'MV-01' },
    { name: 'Fuvahmulah', code: 'MV-02' },
    { name: 'Malé', code: 'MV-03' },
    { name: 'N. Atoll', code: 'MV-04' },
    { name: 'N. Ari Atoll', code: 'MV-05' },
    { name: 'N. Nilandhe Atoll', code: 'MV-06' },
    { name: 'N. Shaviyani Atoll', code: 'MV-07' },
    { name: 'S. Ari Atoll', code: 'MV-08' },
    { name: 'S. Nilandhe Atoll', code: 'MV-09' },
    { name: 'S. Shaviyani Atoll', code: 'MV-10' },
  ],
  Mali: [
    { name: 'Bamako', code: 'ML-BKO' },
    { name: 'Gao', code: 'ML-7' },
    { name: 'Kayes', code: 'ML-1' },
    { name: 'Kidal', code: 'ML-8' },
    { name: 'Koulikoro', code: 'ML-2' },
    { name: 'Mopti', code: 'ML-5' },
    { name: 'Ségou', code: 'ML-4' },
    { name: 'Sikasso', code: 'ML-3' },
    { name: 'Tombouctou', code: 'ML-6' },
  ],
  Malta: [
    { name: 'Attard', code: 'MT-01' },
    { name: 'Birgu', code: 'MT-02' },
    { name: 'Birkirkara', code: 'MT-03' },
    { name: 'Birżebbuġa', code: 'MT-04' },
    { name: 'Cospicua', code: 'MT-05' },
    { name: 'Dingli', code: 'MT-06' },
    { name: 'Fgura', code: 'MT-07' },
    { name: 'Floriana', code: 'MT-08' },
    { name: 'Fontana', code: 'MT-09' },
    { name: 'Għajnsielem', code: 'MT-10' },
    { name: 'Għargħur', code: 'MT-11' },
    { name: 'Għaxaq', code: 'MT-12' },
    { name: 'Gozo', code: 'MT-GO' },
    { name: 'Gżira', code: 'MT-13' },
    { name: 'Ħamrun', code: 'MT-14' },
    { name: 'Iklin', code: 'MT-15' },
    { name: 'Kalkara', code: 'MT-16' },
    { name: 'Kerċem', code: 'MT-17' },
    { name: ' Kirkop', code: 'MT-18' },
    { name: 'Lija', code: 'MT-19' },
    { name: 'Luqa', code: 'MT-20' },
    { name: 'Marsa', code: 'MT-21' },
    { name: 'Marsaskala', code: 'MT-22' },
    { name: 'Marsaxlokk', code: 'MT-23' },
    { name: 'Mdina', code: 'MT-24' },
    { name: 'Mellieħa', code: 'MT-25' },
    { name: 'Mġarr', code: 'MT-26' },
    { name: 'Mosta', code: 'MT-27' },
    { name: 'Mqabba', code: 'MT-28' },
    { name: 'Msida', code: 'MT-29' },
    { name: 'Mtarfa', code: 'MT-30' },
    { name: 'Munxar', code: 'MT-31' },
    { name: 'Nadur', code: 'MT-32' },
    { name: 'Naxxar', code: 'MT-33' },
    { name: 'Paola', code: 'MT-34' },
    { name: 'Pembroke', code: 'MT-35' },
    { name: 'Pietà', code: 'MT-36' },
    { name: 'Qala', code: 'MT-37' },
    { name: 'Qormi', code: 'MT-38' },
    { name: 'Qrendi', code: 'MT-39' },
    { name: 'Rabat', code: 'MT-40' },
    { name: 'Safi', code: 'MT-41' },
    { name: 'San Ġwann', code: 'MT-42' },
    { name: 'San Lawrenz', code: 'MT-43' },
    { name: 'Sannat', code: 'MT-44' },
    { name: 'Santa Luċija', code: 'MT-45' },
    { name: 'Santa Venera', code: 'MT-46' },
    { name: 'Senglea', code: 'MT-47' },
    { name: 'Siġġiewi', code: 'MT-48' },
    { name: 'Sliema', code: 'MT-49' },
    { name: 'Swieqi', code: 'MT-50' },
    { name: 'Tarxien', code: 'MT-51' },
    { name: "Ta' Xbiex", code: 'MT-52' },
    { name: 'Valletta', code: 'MT-53' },
    { name: 'Victoria', code: 'MT-54' },
    { name: 'Xagħra', code: 'MT-55' },
    { name: 'Xagħra', code: 'MT-55' },
    { name: 'Xewkija', code: 'MT-56' },
    { name: 'Xgħajra', code: 'MT-57' },
    { name: 'Żabbar', code: 'MT-58' },
    { name: 'Żebbuġ', code: 'MT-59' },
    { name: 'Żejtun', code: 'MT-60' },
    { name: 'Żurrieq', code: 'MT-61' },
  ],
  'Marshall Islands': [
    { name: 'Ailinglaplap', code: 'MH-ALL' },
    { name: 'Ailuk', code: 'MH-ALK' },
    { name: 'Arno', code: 'MH-ARN' },
    { name: 'Aur', code: 'MH-AUR' },
    { name: 'Bikini', code: 'MH-BIK' },
    { name: 'Ebon', code: 'MH-EBN' },
    { name: 'Enewetak', code: 'MH-ENI' },
    { name: 'Jabat', code: 'MH-JAB' },
    { name: 'Jaluit', code: 'MH-JAL' },
    { name: 'Kili', code: 'MH-KIL' },
    { name: 'Kwajalein', code: 'MH-KWA' },
    { name: 'Lae', code: 'MH-LAE' },
    { name: 'Lib', code: 'MH-LIB' },
    { name: 'Likiep', code: 'MH-LIK' },
    { name: 'Majuro', code: 'MH-MAJ' },
    { name: 'Maloloj', code: 'MH-MAL' },
    { name: 'Mejit', code: 'MH-MEJ' },
    { name: 'Mili', code: 'MH-MIL' },
    { name: 'Namorik', code: 'MH-NMK' },
    { name: 'Namu', code: 'MH-NMU' },
    { name: 'Rongelap', code: 'MH-RON' },
    { name: 'Rongrik', code: 'MH-RON' },
    { name: 'Takin', code: 'MH-TAK' },
    { name: 'Ujae', code: 'MH-UJA' },
    { name: 'Ujelang', code: 'MH-UJL' },
    { name: 'Utirik', code: 'MH-UTI' },
    { name: 'Wotho', code: 'MH-WTH' },
    { name: 'Wotje', code: 'MH-WTJ' },
  ],
  Martinique: [
    { name: 'Fort-de-France', code: 'MQ-97209' },
    { name: 'Le Lamentin', code: 'MQ-97213' },
    { name: 'Le Marin', code: 'MQ-97217' },
    { name: 'Saint-Joseph', code: 'MQ-97216' },
    { name: 'Saint-Pierre', code: 'MQ-97211' },
    { name: 'Trinité', code: 'MQ-97214' },
    { name: 'Vauclin', code: 'MQ-97231' },
  ],
  Mexico: [
    { name: 'Aguascalientes', code: 'MX-AGU' },
    { name: 'Baja California', code: 'MX-BCN' },
    { name: 'Baja California Sur', code: 'MX-BCS' },
    { name: 'Campeche', code: 'MX-CAM' },
    { name: 'Chiapas', code: 'MX-CHP' },
    { name: 'Chihuahua', code: 'MX-CHH' },
    { name: 'Coahuila', code: 'MX-COA' },
    { name: 'Colima', code: 'MX-COL' },
    { name: 'Durango', code: 'MX-DUR' },
    { name: 'Guanajuato', code: 'MX-GUA' },
    { name: 'Guerrero', code: 'MX-GRO' },
    { name: 'Hidalgo', code: 'MX-HID' },
    { name: 'Jalisco', code: 'MX-JAL' },
    { name: 'Estado de México', code: 'MX-MEX' },
    { name: 'Michoacán', code: 'MX-MIC' },
    { name: 'Morelos', code: 'MX-MOR' },
    { name: 'Nayarit', code: 'MX-NAY' },
    { name: 'Nuevo León', code: 'MX-NLE' },
    { name: 'Oaxaca', code: 'MX-OAX' },
    { name: 'Puebla', code: 'MX-PUE' },
    { name: 'Querétaro', code: 'MX-QUE' },
    { name: 'Quintana Roo', code: 'MX-ROO' },
    { name: 'San Luis Potosí', code: 'MX-SLP' },
    { name: 'Sinaloa', code: 'MX-SIN' },
    { name: 'Sonora', code: 'MX-SON' },
    { name: 'Tabasco', code: 'MX-TAB' },
    { name: 'Tamaulipas', code: 'MX-TAM' },
    { name: 'Tlaxcala', code: 'MX-TLA' },
    { name: 'Veracruz', code: 'MX-VER' },
    { name: 'Yucatán', code: 'MX-YUC' },
    { name: 'Zacatecas', code: 'MX-ZAC' },
  ],
  Netherlands: [
    { name: 'Drenthe', code: 'NL-DR' },
    { name: 'Flevoland', code: 'NL-FL' },
    { name: 'Friesland', code: 'NL-FR' },
    { name: 'Gelderland', code: 'NL-GE' },
    { name: 'Groningen', code: 'NL-GR' },
    { name: 'Limburg', code: 'NL-LI' },
    { name: 'Noord-Brabant', code: 'NL-NB' },
    { name: 'Noord-Holland', code: 'NL-NH' },
    { name: 'Overijssel', code: 'NL-OV' },
    { name: 'Utrecht', code: 'NL-UT' },
    { name: 'Zeeland', code: 'NL-ZE' },
    { name: 'Zuid-Holland', code: 'NL-ZH' },
  ],
  'New Zealand': [
    { name: 'Auckland', code: 'NZ-AUK' },
    { name: 'Bay of Plenty', code: 'NZ-BOP' },
    { name: 'Canterbury', code: 'NZ-CAN' },
    { name: 'Gisborne', code: 'NZ-GIS' },
    { name: "Hawke's Bay", code: 'NZ-HKB' },
    { name: 'Manawatu-Wanganui', code: 'NZ-MWT' },
    { name: 'Marlborough', code: 'NZ-MBH' },
    { name: 'Nelson', code: 'NZ-NEL' },
    { name: 'Northland', code: 'NZ-NTL' },
    { name: 'Otago', code: 'NZ-OTA' },
    { name: 'Southland', code: 'NZ-STL' },
    { name: 'Taranaki', code: 'NZ-TKI' },
    { name: 'Tasman', code: 'NZ-TAS' },
    { name: 'Waikato', code: 'NZ-WKO' },
    { name: 'Wellington', code: 'NZ-WGN' },
    { name: 'West Coast', code: 'NZ-WTC' },
  ],
  Norway: [
    { name: 'Akershus', code: 'NO-02' },
    { name: 'Aust-Agder', code: 'NO-09' },
    { name: 'Buskerud', code: 'NO-06' },
    { name: 'Finnmark', code: 'NO-20' },
    { name: 'Hedmark', code: 'NO-04' },
    { name: 'Hordaland', code: 'NO-12' },
    { name: 'Møre og Romsdal', code: 'NO-15' },
    { name: 'Nordland', code: 'NO-18' },
    { name: 'Nord-Trøndelag', code: 'NO-17' },
    { name: 'Oppland', code: 'NO-05' },
    { name: 'Oslo', code: 'NO-03' },
    { name: 'Østfold', code: 'NO-01' },
    { name: 'Rogaland', code: 'NO-11' },
    { name: 'Sogn og Fjordane', code: 'NO-14' },
    { name: 'Sør-Trøndelag', code: 'NO-16' },
    { name: 'Telemark', code: 'NO-08' },
    { name: 'Troms', code: 'NO-19' },
    { name: 'Trøndelag', code: 'NO-50' },
    { name: 'Vest-Agder', code: 'NO-10' },
    { name: 'Vestfold', code: 'NO-07' },
  ],
  Peru: [
    { name: 'Amazonas', code: 'PE-AMA' },
    { name: 'Ancash', code: 'PE-ANC' },
    { name: 'Apurímac', code: 'PE-APU' },
    { name: 'Arequipa', code: 'PE-ARE' },
    { name: 'Ayacucho', code: 'PE-AYA' },
    { name: 'Cajamarca', code: 'PE-CAJ' },
    { name: 'Callao', code: 'PE-CAL' },
    { name: 'Cusco', code: 'PE-CUS' },
    { name: 'Huancavelica', code: 'PE-HUV' },
    { name: 'Huánuco', code: 'PE-HUC' },
    { name: 'Ica', code: 'PE-ICA' },
    { name: 'Junín', code: 'PE-JUN' },
    { name: 'La Libertad', code: 'PE-LAL' },
    { name: 'Lambayeque', code: 'PE-LAM' },
    { name: 'Lima', code: 'PE-LIM' },
    { name: 'Loreto', code: 'PE-LOR' },
    { name: 'Madre de Dios', code: 'PE-MDD' },
    { name: 'Moquegua', code: 'PE-MOQ' },
    { name: 'Pasco', code: 'PE-PAS' },
    { name: 'Piura', code: 'PE-PIU' },
    { name: 'Puno', code: 'PE-PUN' },
    { name: 'San Martín', code: 'PE-SAM' },
    { name: 'Tacna', code: 'PE-TAC' },
    { name: 'Tumbes', code: 'PE-TUM' },
    { name: 'Ucayali', code: 'PE-UCA' },
  ],
  Poland: [
    { name: 'Baja Silesia', code: 'PL-DS' },
    { name: 'Cuyavia y Pomerania', code: 'PL-KP' },
    { name: 'Gran Polonia', code: 'PL-WP' },
    { name: 'Lublin', code: 'PL-LU' },
    { name: 'Lubusz', code: 'PL-LB' },
    { name: 'Mazovia', code: 'PL-MZ' },
    { name: 'Opole', code: 'PL-OP' },
    { name: 'Podlaquia', code: 'PL-PD' },
    { name: 'Pomerania', code: 'PL-PM' },
    { name: 'Silesia', code: 'PL-SL' },
    { name: 'Subcarpacia', code: 'PL-PK' },
    { name: 'Świętokrzyskie', code: 'PL-SK' },
    { name: 'Varmia y Masuria', code: 'PL-WN' },
    { name: 'West Pomerania', code: 'PL-ZP' },
  ],
  Portugal: [
    { name: 'Aveiro', code: 'PT-01' },
    { name: 'Beja', code: 'PT-02' },
    { name: 'Braga', code: 'PT-03' },
    { name: 'Bragança', code: 'PT-04' },
    { name: 'Castelo Branco', code: 'PT-05' },
    { name: 'Coimbra', code: 'PT-06' },
    { name: 'Évora', code: 'PT-07' },
    { name: 'Faro', code: 'PT-08' },
    { name: 'Guarda', code: 'PT-09' },
    { name: 'Leiria', code: 'PT-10' },
    { name: 'Lisboa', code: 'PT-11' },
    { name: 'Portalegre', code: 'PT-12' },
    { name: 'Porto', code: 'PT-13' },
    { name: 'Santarém', code: 'PT-14' },
    { name: 'Setúbal', code: 'PT-15' },
    { name: 'Viana do Castelo', code: 'PT-16' },
    { name: 'Vila Real', code: 'PT-17' },
    { name: 'Viseu', code: 'PT-18' },
  ],
  Russia: [
    { name: 'Adygea', code: 'RU-AD' },
    { name: 'Altai', code: 'RU-ALT' },
    { name: 'Altaiski Krai', code: 'RU-ALT' },
    { name: 'Amur', code: 'RU-AMU' },
    { name: "Arkhangel'sk", code: 'RU-ARK' },
    { name: 'Astrakhan', code: 'RU-AST' },
    { name: 'Bashkortostan', code: 'RU-BA' },
    { name: 'Belgorod', code: 'RU-BEL' },
    { name: 'Bryansk', code: 'RU-BRY' },
    { name: 'Buryatia', code: 'RU-BU' },
    { name: 'Chechenia', code: 'RU-CE' },
    { name: 'Chelyabinsk', code: 'RU-CHE' },
    { name: 'Chuvashia', code: 'RU-CU' },
    { name: 'Dagestan', code: 'RU-DA' },
    { name: 'Ingushetia', code: 'RU-IN' },
    { name: 'Irkutsk', code: 'RU-IRK' },
    { name: 'Ivanovo', code: 'RU-IVA' },
    { name: 'Kaliningrad', code: 'RU-KAL' },
    { name: 'Kalmykia', code: 'RU-KL' },
    { name: 'Kaluga', code: 'RU-KLU' },
    { name: 'Karelia', code: 'RU-KR' },
    { name: 'Kemerovo', code: 'RU-KEM' },
    { name: 'Khabarovsk', code: 'RU-KHA' },
    { name: 'Khakasia', code: 'RU-KK' },
    { name: 'Khanty-Mansi', code: 'RU-KHM' },
    { name: 'Kirov', code: 'RU-KIR' },
    { name: 'Komi', code: 'RU-KO' },
    { name: 'Kostroma', code: 'RU-KOS' },
    { name: 'Krasnodar', code: 'RU-KDA' },
    { name: 'Krasnoyarsk', code: 'RU-KYA' },
    { name: 'Kurgan', code: 'RU-KGN' },
    { name: 'Kursk', code: 'RU-KRS' },
    { name: 'Leningrad', code: 'RU-LEN' },
    { name: 'Lipetsk', code: 'RU-LIP' },
    { name: 'Magadan', code: 'RU-MAG' },
    { name: 'Mari El', code: 'RU-ME' },
    { name: 'Mordovia', code: 'RU-MO' },
    { name: 'Moscow', code: 'RU-MOW' },
    { name: 'Murmansk', code: 'RU-MUR' },
    { name: 'Nizhny Novgorod', code: 'RU-NIZ' },
    { name: 'North Ossetia', code: 'RU-SE' },
    { name: 'Novgorod', code: 'RU-NGR' },
    { name: 'Novosibirsk', code: 'RU-NVS' },
    { name: 'Omsk', code: 'RU-OMS' },
    { name: 'Orenburg', code: 'RU-ORE' },
    { name: 'Oryol', code: 'RU-ORL' },
    { name: 'Penza', code: 'RU-PNZ' },
    { name: 'Perm', code: 'RU-PER' },
    { name: 'Pskov', code: 'RU-PSK' },
    { name: 'Rostov', code: 'RU-ROS' },
    { name: 'Ryazan', code: 'RU-RYA' },
    { name: 'Saint Petersburg', code: 'RU-SPE' },
    { name: 'Sakha', code: 'RU-SAK' },
    { name: 'Sakhalin', code: 'RU-SAK' },
    { name: 'Samara', code: 'RU-SAM' },
    { name: 'Saratov', code: 'RU-SAR' },
    { name: 'Smolensk', code: 'RU-SMO' },
    { name: 'Stavropol', code: 'RU-STA' },
    { name: 'Sverdlovsk', code: 'RU-SVE' },
    { name: 'Tambov', code: 'RU-TAM' },
    { name: 'Tatarstan', code: 'RU-TA' },
    { name: 'Tver', code: 'RU-TVE' },
    { name: 'Tyumen', code: 'RU-TYU' },
    { name: 'Udmurtia', code: 'RU-UD' },
    { name: 'Ulyanovsk', code: 'RU-ULY' },
    { name: 'Vladimir', code: 'RU-VLA' },
    { name: 'Volgograd', code: 'RU-VGG' },
    { name: 'Vologda', code: 'RU-VLG' },
    { name: 'Voronezh', code: 'RU-VOR' },
    { name: 'Yamalo-Nenets', code: 'RU-YAN' },
    { name: 'Yaroslavl', code: 'RU-YAR' },
    { name: 'Zabaykalsky', code: 'RU-ZAB' },
  ],
  'South Africa': [
    { name: 'Eastern Cape', code: 'ZA-EC' },
    { name: 'Free State', code: 'ZA-FS' },
    { name: 'Gauteng', code: 'ZA-GP' },
    { name: 'KwaZulu-Natal', code: 'ZA-KZN' },
    { name: 'Limpopo', code: 'ZA-LP' },
    { name: 'Mpumalanga', code: 'ZA-MP' },
    { name: 'North West', code: 'ZA-NW' },
    { name: 'Northern Cape', code: 'ZA-NC' },
    { name: 'Western Cape', code: 'ZA-WC' },
  ],
  'South Korea': [
    { name: 'Busan', code: 'KR-26' },
    { name: 'Chungbuk', code: 'KR-43' },
    { name: 'Chungnam', code: 'KR-44' },
    { name: 'Daegu', code: 'KR-27' },
    { name: 'Daejeon', code: 'KR-30' },
    { name: 'Gangwon', code: 'KR-42' },
    { name: 'Gwangju', code: 'KR-29' },
    { name: 'Gyeongbuk', code: 'KR-47' },
    { name: 'Gyeonggi', code: 'KR-41' },
    { name: 'Gyeongnam', code: 'KR-48' },
    { name: 'Incheon', code: 'KR-28' },
    { name: 'Jeju', code: 'KR-49' },
    { name: 'Jeonbuk', code: 'KR-45' },
    { name: 'Jeonnam', code: 'KR-46' },
    { name: 'North Jeolla', code: 'KR-JB' },
    { name: 'North Gyeongsang', code: 'KR-GB' },
    { name: 'Seoul', code: 'KR-11' },
    { name: 'South Chungcheong', code: 'KR-CC' },
    { name: 'South Gyeongsang', code: 'KR-GS' },
    { name: 'South Jeolla', code: 'KR-JN' },
    { name: 'Ulsan', code: 'KR-31' },
  ],
  Spain: [
    { name: 'Andalusia', code: 'ES-AN' },
    { name: 'Aragon', code: 'ES-AR' },
    { name: 'Asturias', code: 'ES-AS' },
    { name: 'Balearic Islands', code: 'ES-IB' },
    { name: 'Basque Country', code: 'ES-PV' },
    { name: 'Canary Islands', code: 'ES-CN' },
    { name: 'Cantabria', code: 'ES-CB' },
    { name: 'Castile and León', code: 'ES-CL' },
    { name: 'Castile-La Mancha', code: 'ES-CM' },
    { name: 'Catalonia', code: 'ES-CT' },
    { name: 'Ceuta', code: 'ES-CE' },
    { name: 'Extremadura', code: 'ES-EX' },
    { name: 'Galicia', code: 'ES-GA' },
    { name: 'La Rioja', code: 'ES-RI' },
    { name: 'Madrid', code: 'ES-MD' },
    { name: 'Melilla', code: 'ES-ML' },
    { name: 'Murcia', code: 'ES-MC' },
    { name: 'Navarre', code: 'ES-NC' },
    { name: 'Valencian Community', code: 'ES-VC' },
  ],
  Sweden: [
    { name: 'Blekinge', code: 'SE-K' },
    { name: 'Dalarna', code: 'SE-W' },
    { name: 'Gävleborg', code: 'SE-X' },
    { name: 'Gotland', code: 'SE-I' },
    { name: 'Halland', code: 'SE-N' },
    { name: 'Jämtland', code: 'SE-Z' },
    { name: 'Jönköping', code: 'SE-F' },
    { name: 'Kalmar', code: 'SE-H' },
    { name: 'Kronoberg', code: 'SE-G' },
    { name: 'Norrbotten', code: 'SE-BD' },
    { name: 'Örebro', code: 'SE-T' },
    { name: 'Östergötland', code: 'SE-E' },
    { name: 'Skåne', code: 'SE-M' },
    { name: 'Södermanland', code: 'SE-D' },
    { name: 'Stockholm', code: 'SE-AB' },
    { name: 'Uppsala', code: 'SE-C' },
    { name: 'Värmland', code: 'SE-S' },
    { name: 'Västerbotten', code: 'SE-AC' },
    { name: 'Västernorrland', code: 'SE-Y' },
    { name: 'Västmanland', code: 'SE-U' },
    { name: 'Västra Götaland', code: 'SE-O' },
  ],
  Switzerland: [
    { name: 'Aargau', code: 'CH-AG' },
    { name: 'Appenzell Ausserrhoden', code: 'CH-AR' },
    { name: 'Appenzell Innerrhoden', code: 'CH-AI' },
    { name: 'Basel-Landschaft', code: 'CH-BL' },
    { name: 'Basel-Stadt', code: 'CH-BS' },
    { name: 'Bern', code: 'CH-BE' },
    { name: 'Fribourg', code: 'CH-FR' },
    { name: 'Geneva', code: 'CH-GE' },
    { name: 'Glarus', code: 'CH-GL' },
    { name: 'Grisons', code: 'CH-GR' },
    { name: 'Jura', code: 'CH-JU' },
    { name: 'Lucerne', code: 'CH-LU' },
    { name: 'Neuchâtel', code: 'CH-NE' },
    { name: 'Nidwalden', code: 'CH-NW' },
    { name: 'Obwalden', code: 'CH-OW' },
    { name: 'Schaffhausen', code: 'CH-SH' },
    { name: 'Schwyz', code: 'CH-SZ' },
    { name: 'Solothurn', code: 'CH-SO' },
    { name: 'St. Gallen', code: 'CH-SG' },
    { name: 'Thurgau', code: 'CH-TG' },
    { name: 'Ticino', code: 'CH-TI' },
    { name: 'Uri', code: 'CH-UR' },
    { name: 'Valais', code: 'CH-VS' },
    { name: 'Vaud', code: 'CH-VD' },
    { name: 'Zug', code: 'CH-ZG' },
    { name: 'Zurich', code: 'CH-ZH' },
  ],
  Turkey: [
    { name: 'Adana', code: 'TR-01' },
    { name: 'Adıyaman', code: 'TR-02' },
    { name: 'Afyonkarahisar', code: 'TR-03' },
    { name: 'Ağrı', code: 'TR-04' },
    { name: 'Amasya', code: 'TR-05' },
    { name: 'Ankara', code: 'TR-06' },
    { name: 'Antalya', code: 'TR-07' },
    { name: 'Artvin', code: 'TR-08' },
    { name: 'Aydın', code: 'TR-09' },
    { name: 'Balıkesir', code: 'TR-10' },
    { name: 'Bilecik', code: 'TR-11' },
    { name: 'Bingöl', code: 'TR-12' },
    { name: 'Bitlis', code: 'TR-13' },
    { name: 'Bolu', code: 'TR-14' },
    { name: 'Burdur', code: 'TR-15' },
    { name: 'Bursa', code: 'TR-16' },
    { name: 'Çanakkale', code: 'TR-17' },
    { name: 'Çankırı', code: 'TR-18' },
    { name: 'Çorum', code: 'TR-19' },
    { name: 'Denizli', code: 'TR-20' },
    { name: 'Diyarbakır', code: 'TR-21' },
    { name: 'Edirne', code: 'TR-22' },
    { name: 'Elazığ', code: 'TR-23' },
    { name: 'Erzincan', code: 'TR-24' },
    { name: 'Erzurum', code: 'TR-25' },
    { name: 'Eskişehir', code: 'TR-26' },
    { name: 'Gaziantep', code: 'TR-27' },
    { name: 'Giresun', code: 'TR-28' },
    { name: 'Gümüşhane', code: 'TR-29' },
    { name: 'Hakkâri', code: 'TR-30' },
    { name: 'Hatay', code: 'TR-31' },
    { name: 'Iğdır', code: 'TR-32' },
    { name: 'Isparta', code: 'TR-33' },
    { name: 'İstanbul', code: 'TR-34' },
    { name: 'İzmir', code: 'TR-35' },
    { name: 'Kahramanmaraş', code: 'TR-36' },
    { name: 'Karabük', code: 'TR-37' },
    { name: 'Karaman', code: 'TR-38' },
    { name: 'Kars', code: 'TR-39' },
    { name: 'Kastamonu', code: 'TR-40' },
    { name: 'Kayseri', code: 'TR-41' },
    { name: 'Kırklareli', code: 'TR-42' },
    { name: 'Kırşehir', code: 'TR-43' },
    { name: 'Kocaeli', code: 'TR-44' },
    { name: 'Konya', code: 'TR-45' },
    { name: 'Kütahya', code: 'TR-46' },
    { name: 'Malatya', code: 'TR-47' },
    { name: 'Manisa', code: 'TR-48' },
    { name: 'Mardin', code: 'TR-49' },
    { name: 'Mersin', code: 'TR-50' },
    { name: 'Muğla', code: 'TR-51' },
    { name: 'Muş', code: 'TR-52' },
    { name: 'Nevşehir', code: 'TR-53' },
    { name: 'Niğde', code: 'TR-54' },
    { name: 'Ordu', code: 'TR-55' },
    { name: 'Osmaniye', code: 'TR-56' },
    { name: 'Rize', code: 'TR-57' },
    { name: 'Sakarya', code: 'TR-58' },
    { name: 'Samsun', code: 'TR-59' },
    { name: 'Siirt', code: 'TR-60' },
    { name: 'Sinop', code: 'TR-61' },
    { name: 'Sivas', code: 'TR-62' },
    { name: 'Şanlıurfa', code: 'TR-63' },
    { name: 'Şırnak', code: 'TR-64' },
    { name: 'Tekirdağ', code: 'TR-65' },
    { name: 'Tokat', code: 'TR-66' },
    { name: 'Trabzon', code: 'TR-67' },
    { name: 'Tunceli', code: 'TR-68' },
    { name: 'Uşak', code: 'TR-69' },
    { name: 'Van', code: 'TR-70' },
    { name: 'Yalova', code: 'TR-71' },
    { name: 'Yozgat', code: 'TR-72' },
    { name: 'Zonguldak', code: 'TR-73' },
  ],
  Ukraine: [
    { name: 'Cherkasy', code: 'UA-01' },
    { name: 'Chernihiv', code: 'UA-02' },
    { name: 'Chernivtsi', code: 'UA-03' },
    { name: 'Crimea', code: 'UA-11' },
    { name: 'Dnipropetrovsk', code: 'UA-04' },
    { name: 'Donetsk', code: 'UA-05' },
    { name: 'Ivano-Frankivsk', code: 'UA-06' },
    { name: 'Kharkiv', code: 'UA-07' },
    { name: 'Kherson', code: 'UA-08' },
    { name: 'Khmelnytskyi', code: 'UA-09' },
    { name: 'Kiev', code: 'UA-10' },
    { name: 'Kirovohrad', code: 'UA-12' },
    { name: 'Luhansk', code: 'UA-13' },
    { name: 'Lviv', code: 'UA-14' },
    { name: 'Mykolaiv', code: 'UA-15' },
    { name: 'Odessa', code: 'UA-16' },
    { name: 'Poltava', code: 'UA-17' },
    { name: 'Rivne', code: 'UA-18' },
    { name: 'Sumy', code: 'UA-19' },
    { name: 'Ternopil', code: 'UA-20' },
    { name: 'Vinnytsia', code: 'UA-21' },
    { name: 'Volyn', code: 'UA-22' },
    { name: 'Zakarpattia', code: 'UA-23' },
    { name: 'Zaporizhzhia', code: 'UA-24' },
    { name: 'Zhytomyr', code: 'UA-25' },
  ],
  'United Kingdom': [
    { name: 'England', code: 'GB-ENG' },
    { name: 'Northern Ireland', code: 'GB-NIR' },
    { name: 'Scotland', code: 'GB-SCT' },
    { name: 'Wales', code: 'GB-WLS' },
  ],
  'United States': [
    { name: 'Alabama', code: 'US-AL' },
    { name: 'Alaska', code: 'US-AK' },
    { name: 'Arizona', code: 'US-AZ' },
    { name: 'Arkansas', code: 'US-AR' },
    { name: 'California', code: 'US-CA' },
    { name: 'Colorado', code: 'US-CO' },
    { name: 'Connecticut', code: 'US-CT' },
    { name: 'Delaware', code: 'US-DE' },
    { name: 'Florida', code: 'US-FL' },
    { name: 'Georgia', code: 'US-GA' },
    { name: 'Hawaii', code: 'US-HI' },
    { name: 'Idaho', code: 'US-ID' },
    { name: 'Illinois', code: 'US-IL' },
    { name: 'Indiana', code: 'US-IN' },
    { name: 'Iowa', code: 'US-IA' },
    { name: 'Kansas', code: 'US-KS' },
    { name: 'Kentucky', code: 'US-KY' },
    { name: 'Louisiana', code: 'US-LA' },
    { name: 'Maine', code: 'US-ME' },
    { name: 'Maryland', code: 'US-MD' },
    { name: 'Massachusetts', code: 'US-MA' },
    { name: 'Michigan', code: 'US-MI' },
    { name: 'Minnesota', code: 'US-MN' },
    { name: 'Mississippi', code: 'US-MS' },
    { name: 'Missouri', code: 'US-MO' },
    { name: 'Montana', code: 'US-MT' },
    { name: 'Nebraska', code: 'US-NE' },
    { name: 'Nevada', code: 'US-NV' },
    { name: 'New Hampshire', code: 'US-NH' },
    { name: 'New Jersey', code: 'US-NJ' },
    { name: 'New Mexico', code: 'US-NM' },
    { name: 'New York', code: 'US-NY' },
    { name: 'North Carolina', code: 'US-NC' },
    { name: 'North Dakota', code: 'US-ND' },
    { name: 'Ohio', code: 'US-OH' },
    { name: 'Oklahoma', code: 'US-OK' },
    { name: 'Oregon', code: 'US-OR' },
    { name: 'Pennsylvania', code: 'US-PA' },
    { name: 'Rhode Island', code: 'US-RI' },
    { name: 'South Carolina', code: 'US-SC' },
    { name: 'South Dakota', code: 'US-SD' },
    { name: 'Tennessee', code: 'US-TN' },
    { name: 'Texas', code: 'US-TX' },
    { name: 'Utah', code: 'US-UT' },
    { name: 'Vermont', code: 'US-VT' },
    { name: 'Virginia', code: 'US-VA' },
    { name: 'Washington', code: 'US-WA' },
    { name: 'West Virginia', code: 'US-WV' },
    { name: 'Wisconsin', code: 'US-WI' },
    { name: 'Wyoming', code: 'US-WY' },
  ],
  Uruguay: [
    { name: 'Artigas', code: 'UY-AR' },
    { name: 'Canelones', code: 'UY-CA' },
    { name: 'Cerro Largo', code: 'UY-CL' },
    { name: 'Colonia', code: 'UY-CO' },
    { name: 'Durazno', code: 'UY-DU' },
    { name: 'Flores', code: 'UY-FS' },
    { name: 'Florida', code: 'UY-FD' },
    { name: 'Lavalleja', code: 'UY-LA' },
    { name: 'Maldonado', code: 'UY-MA' },
    { name: 'Montevideo', code: 'UY-MO' },
    { name: 'Paysandú', code: 'UY-PA' },
    { name: 'Río Negro', code: 'UY-RN' },
    { name: 'Rivera', code: 'UY-RV' },
    { name: 'Rocha', code: 'UY-RO' },
    { name: 'Salto', code: 'UY-SA' },
    { name: 'San José', code: 'UY-SJ' },
    { name: 'Soriano', code: 'UY-SO' },
    { name: 'Tacuarembó', code: 'UY-TA' },
    { name: 'Treinta y Tres', code: 'UY-TT' },
  ],
  Uzbekistan: [
    { name: 'Andijan', code: 'UZ-AN' },
    { name: 'Bukhara', code: 'UZ-BU' },
    { name: 'Fergana', code: 'UZ-FA' },
    { name: 'Jizzakh', code: 'UZ-JI' },
    { name: 'Karakalpakstan', code: 'UZ-QR' },
    { name: 'Kashkadarya', code: 'UZ-QA' },
    { name: 'Khorezm', code: 'UZ-XO' },
    { name: 'Namangan', code: 'UZ-NG' },
    { name: 'Navoiy', code: 'UZ-NW' },
    { name: 'Samarkand', code: 'UZ-SA' },
    { name: 'Sirdaryo', code: 'UZ-SI' },
    { name: 'Surkhandarya', code: 'UZ-SU' },
    { name: 'Syrdarya', code: 'UZ-SR' },
    { name: 'Tashkent', code: 'UZ-TO' },
  ],
  Venezuela: [
    { name: 'Amazonas', code: 'VE-Z' },
    { name: 'Anzoátegui', code: 'VE-B' },
    { name: 'Apure', code: 'VE-C' },
    { name: 'Aragua', code: 'VE-D' },
    { name: 'Barinas', code: 'VE-E' },
    { name: 'Bolívar', code: 'VE-F' },
    { name: 'Carabobo', code: 'VE-G' },
    { name: 'Cojedes', code: 'VE-H' },
    { name: 'Delta Amacuro', code: 'VE-Y' },
    { name: 'Falcón', code: 'VE-I' },
    { name: 'Guárico', code: 'VE-J' },
    { name: 'Lara', code: 'VE-K' },
    { name: 'Mérida', code: 'VE-L' },
    { name: 'Miranda', code: 'VE-M' },
    { name: 'Monagas', code: 'VE-N' },
    { name: 'Nueva Esparta', code: 'VE-O' },
    { name: 'Portuguesa', code: 'VE-P' },
    { name: 'Sucre', code: 'VE-R' },
    { name: 'Táchira', code: 'VE-S' },
    { name: 'Trujillo', code: 'VE-T' },
    { name: 'Vargas', code: 'VE-X' },
    { name: 'Yaracuy', code: 'VE-U' },
    { name: 'Zulia', code: 'VE-V' },
  ],
  Vietnam: [
    { name: 'An Giang', code: 'VN-44' },
    { name: 'Bà Rịa-Vũng Tàu', code: 'VN-43' },
    { name: 'Bình Dương', code: 'VN-57' },
    { name: 'Bình Phước', code: 'VN-58' },
    { name: 'Bình Thuận', code: 'VN-40' },
    { name: 'Cà Mau', code: 'VN-47' },
    { name: 'Cần Thơ', code: 'VN-CT' },
    { name: 'Đà Nẵng', code: 'VN-DN' },
    { name: 'Đắk Lắk', code: 'VN-33' },
    { name: 'Đắk Nông', code: 'VN-72' },
    { name: 'Đồng Nai', code: 'VN-39' },
    { name: 'Đồng Tháp', code: 'VN-45' },
    { name: 'Gia Lai', code: 'VN-30' },
    { name: 'Hà Giang', code: 'VN-03' },
    { name: 'Hà Nam', code: 'VN-63' },
    { name: 'Hà Nội', code: 'VN-HN' },
    { name: 'Hà Tĩnh', code: 'VN-23' },
    { name: 'Hải Dương', code: 'VN-61' },
    { name: 'Hải Phòng', code: 'VN-HP' },
    { name: 'Hậu Giang', code: 'VN-73' },
    { name: 'Hoà Bình', code: 'VN-14' },
    { name: 'Hưng Yên', code: 'VN-66' },
    { name: 'Khánh Hòa', code: 'VN-34' },
    { name: 'Kiên Giang', code: 'VN-47' },
    { name: 'Kon Tum', code: 'VN-28' },
    { name: 'Lai Châu', code: 'VN-01' },
    { name: 'Lâm Đồng', code: 'VN-35' },
    { name: 'Lạng Sơn', code: 'VN-09' },
    { name: 'Lào Cai', code: 'VN-02' },
    { name: 'Long An', code: 'VN-41' },
    { name: 'Nam Định', code: 'VN-67' },
    { name: 'Nghệ An', code: 'VN-22' },
    { name: 'Ninh Bình', code: 'VN-18' },
    { name: 'Ninh Thuận', code: 'VN-36' },
    { name: 'Phú Thọ', code: 'VN-68' },
    { name: 'Phú Yên', code: 'VN-32' },
    { name: 'Quảng Bình', code: 'VN-24' },
    { name: 'Quảng Nam', code: 'VN-27' },
    { name: 'Quảng Ngãi', code: 'VN-29' },
    { name: 'Quảng Ninh', code: 'VN-13' },
    { name: 'Quảng Trị', code: 'VN-25' },
    { name: 'Sóc Trăng', code: 'VN-52' },
    { name: 'Sơn La', code: 'VN-05' },
    { name: 'Tây Ninh', code: 'VN-37' },
    { name: 'Thái Bình', code: 'VN-20' },
    { name: 'Thái Nguyên', code: 'VN-69' },
    { name: 'Thanh Hóa', code: 'VN-21' },
    { name: 'Thừa Thiên-Huế', code: 'VN-26' },
    { name: 'Tiền Giang', code: 'VN-46' },
    { name: 'Trà Vinh', code: 'VN-51' },
    { name: 'Tuyên Quang', code: 'VN-07' },
    { name: 'Vĩnh Long', code: 'VN-49' },
    { name: 'Vĩnh Phúc', code: 'VN-70' },
    { name: 'Yên Bái', code: 'VN-06' },
  ],
  Yemen: [
    { name: 'Abyan', code: 'YE-AB' },
    { name: 'Aden', code: 'YE-AD' },
    { name: 'Al Bayda', code: 'YE-BA' },
    { name: 'Al Hudaydah', code: 'YE-HU' },
    { name: 'Al Jawf', code: 'YE-JA' },
    { name: 'Al Mahrah', code: 'YE-MR' },
    { name: 'Al Mahwit', code: 'YE-MW' },
    { name: 'Amanat Al Asimah', code: 'YE-SA' },
    { name: 'Amran', code: 'YE-AM' },
    { name: 'Dhamar', code: 'YE-DH' },
    { name: 'Hadramaut', code: 'YE-HD' },
    { name: 'Hajjah', code: 'YE-HJ' },
    { name: 'Ibb', code: 'YE-IB' },
    { name: 'Lahij', code: 'YE-LA' },
    { name: "Ma'rib", code: 'YE-MA' },
    { name: 'Mukalla', code: 'YE-MU' },
    { name: 'Raymah', code: 'YE-RA' },
    { name: "Sa'dah", code: 'YE-SD' },
    { name: "Sana'a", code: 'YE-SN' },
    { name: 'Shabwah', code: 'YE-SH' },
    { name: 'Taiz', code: 'YE-TA' },
    { name: 'Yemen', code: 'YE-YE' },
  ],
  Zambia: [
    { name: 'Central', code: 'ZM-02' },
    { name: 'Copperbelt', code: 'ZM-08' },
    { name: 'Eastern', code: 'ZM-03' },
    { name: 'Luapula', code: 'ZM-04' },
    { name: 'Lusaka', code: 'ZM-09' },
    { name: 'North-Western', code: 'ZM-06' },
    { name: 'Northern', code: 'ZM-05' },
    { name: 'Southern', code: 'ZM-07' },
    { name: 'Western', code: 'ZM-01' },
  ],
};
