import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import './flags.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import { Dropdown } from 'primereact/dropdown';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronUpIcon } from 'primereact/icons/chevronup';
import FlagsPng from 'assets/flag_placeholder.png';
import './styles.css';
import { COUNTRIES, PROVINCIES } from './constants';
import { Button } from 'primereact/button';
import { useContact } from 'Providers/ContactProvider';
import { v4 as uuidv4 } from 'uuid';

const DeliveryDataForm = ({ setNewDirectionFlow }) => {
  const {
    addresses,
    updateContactInfo,
    contactInfo,
    addAddress,
    toast,
    selectedCountry,
    setSelectedCountry,
    selectedState,
    setSelectedState,
    resetContactInfo,
  } = useContact();
  const [fieldsError, setFieldsError] = useState({});

  const goToDirectionAvailable = () => setNewDirectionFlow(false);

  const changeData = (event) => {
    if (fieldsError[event.target.id]) {
      setFieldsError({
        ...fieldsError,
        [event.target.id]: false,
      });
    }

    updateContactInfo({ type: event.target.id, value: event.target.value });
  };

  const onSaveDirection = () => {
    if (
      !!selectedCountry &&
      !!selectedState?.name &&
      !!contactInfo?.direction &&
      !!contactInfo?.apartment &&
      !!contactInfo?.cp &&
      !!contactInfo?.city
    ) {
      const newAddress = {
        apartment: contactInfo.apartment,
        city: contactInfo.city,
        cp: contactInfo.cp,
        direction: contactInfo.direction,
        country: selectedCountry,
        state: selectedState.name,
        _id: uuidv4(),
      };
      if (
        addresses.find(
          (addressSaved) => addressSaved.direction === newAddress.direction,
        )
      ) {
        toast.current.show({
          severity: 'warn',
          summary: 'Ya existe está dirección',
          detail:
            'Debes eliminar la dirección almacenada si queres volver a agregarla',
          life: 3000,
        });
      } else {
        addAddress(newAddress);
        setSelectedState(null);
        setSelectedCountry(null);
        setFieldsError({});
        resetContactInfo();
        goToDirectionAvailable();
      }
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Completa los datos de dirección',
        detail: 'Completa todos los campos para poder agregar la dirección',
        life: 3000,
      });
      const newFieldsErrors = {};
      if (!selectedCountry) {
        newFieldsErrors.country = true;
      }
      if (!selectedState?.name) {
        newFieldsErrors.state = true;
      }
      if (!contactInfo?.direction) {
        newFieldsErrors.direction = true;
      }
      if (!contactInfo?.apartment) {
        newFieldsErrors.apartment = true;
      }
      if (!contactInfo?.cp) {
        newFieldsErrors.cp = true;
      }
      if (!contactInfo?.city) {
        newFieldsErrors.city = true;
      }
      setFieldsError(newFieldsErrors);
    }
  };
  const selectedCountryTemplate = (option, props) => {
    const objSelectedCountry = COUNTRIES.find(
      (country) => country.value === selectedCountry,
    );
    if (objSelectedCountry) {
      return (
        <div
          className="flex align-items-center"
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            color: 'var(--gray-800)',
          }}
        >
          <img
            alt={objSelectedCountry.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${objSelectedCountry.code.toLowerCase()}`}
            style={{ width: '18px' }}
          />
          <div
            style={{
              paddingLeft: 16,
            }}
          >
            {objSelectedCountry.name}
          </div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div
        className="flex align-items-center"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          color: 'var(--gray-800)',
        }}
      >
        <img
          alt={option.name}
          src={FlagsPng}
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{
            width: '18px',
            height: '12px',
          }}
        />
        <div
          style={{
            paddingLeft: 16,
          }}
        >
          {option.name}
        </div>
      </div>
    );
  };

  const selectedStateTemplate = (option, props) => {
    if (selectedState) {
      return (
        <div
          className="flex align-items-center"
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            color: 'var(--gray-800)',
            padding: 0,
          }}
        >
          <div>{selectedState.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const stateOptionTemplate = (option) => {
    return (
      <div
        className="flex align-items-center"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          color: 'var(--gray-800)',
        }}
      >
        <div>{option.name}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? <span></span> : 'Selecciona un país.'}
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {addresses.length !== 0 && (
        <Button
          style={{
            marginBottom: 16,
            color: 'var(--gray-600)',
            maxWidth: 160,
            fontSize: '0.7em',
            padding: '4px 8px',
          }}
          label="Ver direcciones guardadas"
          severity="secondary"
          className="goToDirectionAvailable"
          onClick={goToDirectionAvailable}
          icon="pi pi-arrow-left"
          outlined
        />
      )}
      <Dropdown
        defaultValue={selectedCountry}
        onChange={(e) => {
          setFieldsError({
            ...fieldsError,
            country: false,
          });
          setSelectedCountry(e.value);
          setSelectedState(null);
        }}
        options={COUNTRIES}
        optionLabel="country"
        placeholder="País / Región"
        valueTemplate={selectedCountryTemplate}
        itemTemplate={countryOptionTemplate}
        panelFooterTemplate={panelFooterTemplate}
        dropdownIcon={(opts) => {
          return opts.iconProps['data-pr-overlay-visible'] ? (
            <ChevronUpIcon {...opts.iconProps} color="var(--gray-800)" />
          ) : (
            <ChevronDownIcon {...opts.iconProps} color="var(--gray-800)" />
          );
        }}
        invalid={fieldsError?.country ?? false}
      />
      {!!selectedCountry && selectedCountry?.length !== 0 && (
        <Dropdown
          defaultValue={selectedState?.name}
          onChange={(e) => {
            setFieldsError({
              ...fieldsError,
              state: false,
            });
            setSelectedState(e.value);
          }}
          options={PROVINCIES[selectedCountry]}
          optionLabel="state"
          placeholder="Provincia / Estado"
          valueTemplate={selectedStateTemplate}
          itemTemplate={stateOptionTemplate}
          dropdownIcon={(opts) => {
            return opts.iconProps['data-pr-overlay-visible'] ? (
              <ChevronUpIcon {...opts.iconProps} color="var(--gray-800)" />
            ) : (
              <ChevronDownIcon {...opts.iconProps} color="var(--gray-800)" />
            );
          }}
          style={{ marginTop: 16 }}
          invalid={fieldsError?.state ?? false}
        />
      )}
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <div className="input-div-container" style={{ width: '100%' }}>
          <label className="float-label" htmlFor="city">
            Ciudad
          </label>
          <InputText
            id="city"
            className="p-inputtext-sm"
            style={{ width: '100%' }}
            value={contactInfo?.city}
            onChange={changeData}
            invalid={fieldsError?.city ?? false}
          />
        </div>

        <div className="input-div-container" style={{ width: '100%' }}>
          <label className="float-label" htmlFor="direction">
            Dirección
          </label>
          <InputText
            id="direction"
            className="p-inputtext-sm"
            style={{ width: '100%' }}
            value={contactInfo?.direction}
            onChange={changeData}
            invalid={fieldsError?.direction ?? false}
          />
        </div>

        <div className="input-div-container" style={{ width: '100%' }}>
          <label className="float-label" htmlFor="apartment">
            Casa, apartamento, etc.
          </label>
          <InputText
            id="apartment"
            className="p-inputtext-sm"
            style={{ width: '100%' }}
            value={contactInfo?.apartment}
            onChange={changeData}
            invalid={fieldsError?.apartment ?? false}
          />
        </div>

        <div className="input-div-container" style={{ width: '100%' }}>
          <label className="float-label" htmlFor="postal-code">
            Código postal
          </label>
          <InputText
            id="cp"
            className="p-inputtext-sm"
            style={{ width: '100%' }}
            value={contactInfo?.cp}
            onChange={changeData}
            invalid={fieldsError?.cp ?? false}
          />
        </div>

        <Button
          style={{ marginTop: 16, color: 'var(--gray-600)' }}
          label="Guardar dirección"
          severity="secondary"
          className="saveDirection"
          onClick={onSaveDirection}
          outlined
        />
      </div>
    </div>
  );
};

export default DeliveryDataForm;
