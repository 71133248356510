import './styles.css';
import ContainerCollections from 'components/ContainerCollections';
import TemplateScreen from 'screens/TemplateScreen';

const Home = () => {
  return (
    <TemplateScreen
      Screen={(eventData) => <ContainerCollections eventData={eventData} />}
    />
  );
};

export default Home;
