import React from 'react';
import ResolutionView from './components/ResolutionView';
import TemplateScreen from 'screens/TemplateScreen';

const Resolution = () => {
  return (
    <TemplateScreen
      Screen={(eventData) => <ResolutionView eventData={eventData} />}
    />
  );
};

export default Resolution;
