import React, { Fragment, useState } from 'react';
import { formatNumberWithDots } from './utils';
import styles from './styles.module.css';
import { Tag } from 'primereact/tag';
import { useNavigate } from 'react-router-dom';
import { CARD_WIDTH } from './contants';
import { useCollections } from 'Providers/CollectionsProviders';
import './styles.css';

const Item = ({ item }) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const safeArea = [
    'detailItem',
    'detailItemTag',
    'detailItemPrice',
    'detailItemCart',
    'detailItemCartIcon',
  ];
  const { newItemShoppingCart } = useCollections();

  const handleMouseMove = (e) => {
    const specificDiv = document.getElementById('detailItem');
    if (safeArea.includes(e.target.id) || specificDiv?.contains(e.target)) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const onMouseEnter = () => {
    setIsActive(true);
  };

  const onMouseLeave = () => {
    setIsActive(false);
  };
  const onClick = () => {
    if (isActive) {
      navigate(`/item?id=${item._id}`);
    }
  };
  const addItemInCart = () => {
    newItemShoppingCart(item._id, 1);
  };

  return (
    <div
      style={{
        width: CARD_WIDTH,
        marginRight: 20,
      }}
      className="container"
    >
      <div
        style={{
          minWidth: CARD_WIDTH,
          minHeight: 360,
          overflow: 'hidden',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
        className="container"
      >
        <div
          style={{
            minWidth: CARD_WIDTH,
            minHeight: 360,
            backgroundColor: 'var(--gray-100)',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            backgroundImage: `url(${item.images.find((item) => item.mainImage === true)?.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            opacity: isActive
              ? !item.isAvailable
                ? 0.5
                : 0.9
              : !item.isAvailable
                ? 0.6
                : 1,
            cursor: 'pointer',
            color: 'white',
          }}
          className={`${styles.item} container`}
          onMouseMove={handleMouseMove}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
        >
          <div></div>
          {isActive ? (
            <div
              style={{
                // backgroundColor: 'var(--gray-100)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <div
                style={{
                  // backgroundColor: 'var(--gray-100)',
                  fontSize: '1.2em',
                  fontWeight: 600,
                }}
              >
                Ver más
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: !item.isAvailable ? 'center' : 'space-between',
          alignItems: 'center',
          backgroundColor: 'rgba(183, 184, 185, 0.7)',
          padding: 4,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          minHeight: 40,
        }}
        id={'detailItem'}
      >
        {!item.isAvailable ? (
          <Tag
            style={{ backgroundColor: 'var(--red-600)', color: 'white' }}
            value="Vendido"
            id={'detailItemTag'}
          ></Tag>
        ) : (
          <Fragment>
            <div
              style={{
                display: 'flex',
                fontSize: '0.9em',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '0 4px 0 4px',
                color: 'var(--gray-800)',
                fontFamily: 'sans-serif',
              }}
            >
              <div>{item.name}</div>
              <div
                style={{
                  fontSize: '0.9em',
                  color: 'var(--gray-700)',
                  borderRadius: 5,
                }}
                id={'detailItemPrice'}
              >{`$${formatNumberWithDots(item.price)} ARS`}</div>
            </div>
            <div
              style={{
                marginRight: 4,
                padding: 6,
                backgroundColor: 'var(--gray-400)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
                cursor: 'pointer',
              }}
              className={styles.cartShop}
              onClick={addItemInCart}
              id={'detailItemCart'}
            >
              <i
                id={'detailItemCartIcon'}
                className="pi pi-cart-plus"
                style={{ fontSize: '1.3rem', color: 'var(--gray-50)' }}
              ></i>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Item;
