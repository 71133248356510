import React from 'react';
import { Galleria } from 'primereact/galleria';

const Gallery = ({ images, eventData }) => {
  const width = eventData.width;
  const height = eventData.height;
  const galleriaSize = () => {
    if (width < 768) {
      return {
        width: width * 0.9,
      };
    }
    return {
      width: width * 0.5,
    };
  };

  const mainImageSize = () => {
    if (width < 768) {
      return {
        width: width * 0.6,
        height: height * 0.4,
      };
    }
    return {
      width: 500,
      height: 700,
    };
  };
  const thumbnailTemplateSize = () => {
    if (width < 768) {
      return {
        width: 40,
        height: 30,
      };
    }
    return {
      width: 80,
      height: 60,
    };
  };

  const galleryImages = images.map((image) => ({
    itemImageSrc: image.url,
    thumbnailImageSrc: image.url,
  }));

  const responsiveOptions = [
    {
      breakpoint: '991px',
      numVisible: 4,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '480px',
      numVisible: 3,
    },
  ];
  const itemTemplate = (item) => {
    return (
      <div
        style={{
          backgroundImage: `url(${item.itemImageSrc})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          minWidth: mainImageSize().width,
          height: mainImageSize().height,
        }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <div
        style={{
          backgroundImage: `url(${item.thumbnailImageSrc})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: thumbnailTemplateSize().width,
          height: thumbnailTemplateSize().height,
          marginRight: 10,
          borderRadius: 5,
        }}
      />
    );
  };

  return (
    <Galleria
      value={galleryImages}
      responsiveOptions={responsiveOptions}
      numVisible={4}
      style={{
        width: galleriaSize().width,
        // backgroundColor: 'var(--gray-100)',
        borderRadius: 5,
      }}
      item={itemTemplate}
      thumbnail={thumbnailTemplate}
      changeItemOnIndicatorHover={true}
    />
  );
};

export default Gallery;
