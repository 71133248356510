import React, { Fragment, useRef } from 'react';
import TemplateScreen from 'screens/TemplateScreen';
import ShoppingCartView from './components/ShoppingCardView';
import { Toast } from 'primereact/toast';

const ShoppingCart = () => {
  const toast = useRef(null);
  return (
    <TemplateScreen
      Screen={(eventData) => (
        <Fragment>
          <ShoppingCartView eventData={eventData} toast={toast} />
          <Toast ref={toast} />
        </Fragment>
      )}
    />
  );
};
export default ShoppingCart;
