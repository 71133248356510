import React, { createContext, useContext, useState } from 'react';
import useSessionStorageWithExpiry from 'hooks/useSessionStorageWithExpiry';
import { useCollections } from 'Providers/CollectionsProviders';
import { instance } from 'services/api';
import LoadingWrapper from 'components/LoadingWrapper';

const SessionStorageContext = createContext();

export const SessionStorageProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { setOrderPaymentInCurse } = useCollections();
  const deleteOrdenPaymentInCurse = async (preferenceId) => {
    setLoading(true);
    await instance
      .post('/api/order_payment_delete', { preferenceId: preferenceId })
      .then((res) => {
        if (res?.data?.ok) {
          setOrderPaymentInCurse(null);
        }
      })
      .catch((err) => console.log({ err }));
    setLoading(false);
  };
  const [storedValue, setStoredValue, minutesLeft, secondsLeft, reset] =
    useSessionStorageWithExpiry(
      'orderPaymentInCurse',
      null,
      15,
      deleteOrdenPaymentInCurse,
    );
  return (
    <SessionStorageContext.Provider
      value={[storedValue, setStoredValue, minutesLeft, secondsLeft, reset]}
    >
      <LoadingWrapper loading={loading}>{children}</LoadingWrapper>
    </SessionStorageContext.Provider>
  );
};

export const useSessionStorageInternal = () =>
  useContext(SessionStorageContext);
