import { Toast } from 'primereact/toast';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { instance } from 'services/api';

const CollectionsContext = createContext();

export const CollectionsProvider = ({ children }) => {
  const [collections, setCollections] = useState(null);
  const [search, setSearch] = useState('');
  const [shoppingCart, setShoppingCart] = useState([]);
  const [orderPaymentInCurse, setOrderPaymentInCurse] = useState(null);
  const toast = useRef(null);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const fetchCollectionsFromService = async () => {
    try {
      const response = await instance.get('/api/get_collection');
      const data = response.data.body.items;
      setCollections(data);
      // Guardar datos y la fecha actual en sessionStorage
      sessionStorage.setItem('collections', JSON.stringify(data));
      sessionStorage.setItem('collectionsDate', getCurrentDate());
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

  const newItemShoppingCart = (id, quanty) => {
    if (orderPaymentInCurse?.value?.preferenceId) {
      toast.current.show({
        severity: 'warn',
        summary: 'Existe una orden de pago en curso',
        detail:
          'Debes eliminar la orden de pago existente para modificar el carrito',
        life: 3000,
      });
      return;
    }
    const itemForCart = collections.find((item) => item._id === id);
    let newShoppingCart = [];
    const newItem = {
      item: itemForCart,
      quanty,
    };
    let summaryToast = 'Artículo añadido al carrito';
    let detailToast = `Se ha agregado el artículo ${itemForCart.name}.`;
    let severity = 'success';

    if (shoppingCart.length === 0) {
      newShoppingCart.push(newItem);
    } else {
      if (
        shoppingCart.find(
          (itemInCart) => itemInCart.item._id === itemForCart._id,
        )
      ) {
        shoppingCart.map((itemCart) => {
          if (itemCart.item._id === itemForCart._id) {
            newShoppingCart.push({
              item: itemCart.item,
              quanty:
                itemCart.quanty + quanty > itemForCart.quanty
                  ? itemForCart.quanty
                  : itemCart.quanty + quanty,
            });
            if (itemCart.quanty + quanty > itemForCart.quanty) {
              summaryToast = 'No hay más stock';
              detailToast = `No se puede agregar más de ${itemForCart.quanty} artículo de ${itemForCart.name}.`;
              severity = 'warn';
            } else {
              summaryToast = 'Artículo editado';
              detailToast = `Se ha editado el artículo ${itemForCart.name}.`;
            }
          } else {
            newShoppingCart.push(itemCart);
          }
        });
      } else {
        newShoppingCart = [...shoppingCart, newItem];
      }
    }
    setShoppingCart(newShoppingCart);
    sessionStorage.setItem('shoppingCart', JSON.stringify(newShoppingCart));
    toast.current.show({
      severity,
      summary: summaryToast,
      detail: detailToast,
      life: 3000,
    });
  };

  const clearShoppingCart = () => {
    setShoppingCart([]);
    sessionStorage.setItem('shoppingCart', JSON.stringify([]));
  };

  const deleteItemShoppingCart = (id) => {
    if (orderPaymentInCurse?.value?.preferenceId) {
      toast.current.show({
        severity: 'warn',
        summary: 'Existe una orden de pago en curso',
        detail:
          'Debes eliminar la orden de pago existente para modificar el carrito',
        life: 3000,
      });
      return;
    }
    const itemForCart = collections.find((item) => item._id === id);
    let newShoppingCart = [];
    shoppingCart.map(
      (itemCart) => itemCart.item._id !== id && newShoppingCart.push(itemCart),
    );
    setShoppingCart(newShoppingCart);
    sessionStorage.setItem('shoppingCart', JSON.stringify(newShoppingCart));
    toast.current.show({
      severity: 'error',
      summary: 'Artículo removido',
      detail: `El artículo ${itemForCart.name} se ha removido.`,
      life: 3000,
    });
  };

  const changeQuantyInShoppingCart = (id, operation) => {
    if (orderPaymentInCurse?.value?.preferenceId) {
      toast.current.show({
        severity: 'warn',
        summary: 'Existe una orden de pago en curso',
        detail:
          'Debes eliminar la orden de pago existente para modificar el carrito',
        life: 3000,
      });
      return;
    }
    const itemForCart = collections.find((item) => item._id === id);
    const findItemInShoppingCart = shoppingCart.find(
      (itemInShoppingCart) => itemInShoppingCart.item._id === id,
    );
    if (!findItemInShoppingCart) {
      return;
    }
    let newShoppingCart = [];

    let summaryToast = 'Artículo editado';
    let detailToast = `Se ha editado el artículo ${itemForCart.name}.`;
    let severity = 'info';
    const calculateQuanty = (quanty) => {
      const stockAvailableItem = collections.find(
        (item) => item._id === id,
      ).quanty;
      switch (operation) {
        case '-': {
          if (quanty - 1 < stockAvailableItem) {
            summaryToast = 'Mínimo de stock';
            detailToast = `Al menos debes tener 1 artículo.`;
            severity = 'warn';
          }
          return quanty - 1 < stockAvailableItem
            ? stockAvailableItem
            : quanty - 1;
        }
        case '+': {
          if (quanty + 1 > stockAvailableItem) {
            summaryToast = 'No hay más stock';
            detailToast = `No se puede agregar más de ${stockAvailableItem} artículo de ${itemForCart.name}.`;
            severity = 'warn';
          }
          return quanty + 1 > stockAvailableItem
            ? stockAvailableItem
            : quanty + 1;
        }
      }
    };
    shoppingCart.map((itemCart) =>
      itemCart.item._id === id
        ? newShoppingCart.push({
            item: itemCart.item,
            quanty: calculateQuanty(itemCart.quanty),
          })
        : newShoppingCart.push(itemCart),
    );
    setShoppingCart(newShoppingCart);
    sessionStorage.setItem('shoppingCart', JSON.stringify(newShoppingCart));
    toast.current.show({
      severity,
      summary: summaryToast,
      detail: detailToast,
      life: 3000,
    });
  };

  useEffect(() => {
    const storedCollections = sessionStorage.getItem('collections');
    const storedDate = sessionStorage.getItem('collectionsDate');
    const storedShoppingCart = sessionStorage.getItem('shoppingCart');
    const orderPaymentInCurseStorage = sessionStorage.getItem(
      'orderPaymentInCurse',
    );

    const currentDate = getCurrentDate();

    if (storedCollections && storedDate !== currentDate) {
      setCollections(JSON.parse(storedCollections));
    } else {
      sessionStorage.removeItem('collections');
      sessionStorage.removeItem('collectionsDate');
      fetchCollectionsFromService();
    }
    if (storedShoppingCart) {
      setShoppingCart(JSON.parse(storedShoppingCart));
    }
    if (orderPaymentInCurseStorage) {
      setOrderPaymentInCurse(JSON.parse(orderPaymentInCurseStorage));
    }
  }, []);

  const newSearch = (text) => setSearch(text);

  return (
    <CollectionsContext.Provider
      value={{
        collections,
        setCollections,
        fetchCollectionsFromService,
        newSearch,
        search,
        shoppingCart,
        newItemShoppingCart,
        deleteItemShoppingCart,
        changeQuantyInShoppingCart,
        setOrderPaymentInCurse,
        clearShoppingCart,
      }}
    >
      {children}
      <Toast ref={toast} />
    </CollectionsContext.Provider>
  );
};

// Hook para usar el contexto
export const useCollections = () => useContext(CollectionsContext);
