import { Toast } from 'primereact/toast';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';

const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
  const toast = useRef(null);
  const [contactInfo, setContactInfo] = useState(() => {
    const savedContactInfo = sessionStorage.getItem('contactInfo');
    return savedContactInfo ? JSON.parse(savedContactInfo) : {};
  });

  const [addresses, setAddresses] = useState(() => {
    const savedAddresses = sessionStorage.getItem('addresses');
    return savedAddresses ? JSON.parse(savedAddresses) : [];
  });

  const [selectedCountry, setSelectedCountry] = useState(() => {
    const savedAddresses = sessionStorage.getItem('country');
    return savedAddresses ? JSON.parse(savedAddresses) : null;
  });
  const [selectedState, setSelectedState] = useState(() => {
    const savedAddresses = sessionStorage.getItem('state');
    return savedAddresses ? JSON.parse(savedAddresses) : null;
  });

  const [addressIdSelected, setAddressIdSelected] = useState(() => {
    const savedAddresses = sessionStorage.getItem('addressId');
    return savedAddresses
      ? JSON.parse(savedAddresses)
      : addresses.length !== 0
        ? addresses[0]
        : null;
  });

  const [fieldsError, setFieldsError] = useState({});

  useEffect(() => {
    sessionStorage.setItem('contactInfo', JSON.stringify(contactInfo));
  }, [contactInfo]);

  useEffect(() => {
    sessionStorage.setItem('addresses', JSON.stringify(addresses));
  }, [addresses]);

  useEffect(() => {
    sessionStorage.setItem('country', JSON.stringify(selectedCountry));
  }, [selectedCountry]);

  useEffect(() => {
    sessionStorage.setItem('state', JSON.stringify(selectedState));
  }, [selectedState]);

  useEffect(() => {
    sessionStorage.setItem('addressId', JSON.stringify(addressIdSelected));
  }, [addressIdSelected]);

  const updateContactInfo = (newContactInfo) => {
    setContactInfo({
      ...contactInfo,
      [newContactInfo.type]: newContactInfo.value,
    });
    const newFieldsErrors = {};
    if (!contactInfo?.email) {
      newFieldsErrors.email = true;
    }
    if (!contactInfo?.firstName) {
      newFieldsErrors.firstName = true;
    }
    if (!contactInfo?.lastName) {
      newFieldsErrors.lastName = true;
    }
    if (!contactInfo?.phone) {
      newFieldsErrors.phone = true;
    }
    if (!newContactInfo.value) {
      newFieldsErrors[newContactInfo.type] = true;
    } else {
      newFieldsErrors[newContactInfo.type] = false;
    }
    setFieldsError(newFieldsErrors);
  };

  const resetContactInfo = () =>
    setContactInfo({
      ...contactInfo,
      city: '',
      direction: '',
      apartment: '',
      cp: '',
    });

  const addAddress = (newAddress) => {
    setAddressIdSelected(newAddress._id);
    setAddresses([...addresses, newAddress]);
  };

  const deleteAddress = (id) => {
    const newAddress = addresses.filter((address) => address._id !== id);
    if (newAddress.length !== 0) {
      setAddressIdSelected(newAddress[0]._id);
    }
    setAddresses(newAddress);
  };

  return (
    <ContactContext.Provider
      value={{
        contactInfo,
        updateContactInfo,
        addresses,
        addAddress,
        toast,
        selectedCountry,
        setSelectedCountry,
        selectedState,
        setSelectedState,
        resetContactInfo,
        addressIdSelected,
        setAddressIdSelected,
        deleteAddress,
        fieldsError,
      }}
    >
      {children}
      <Toast ref={toast} />
    </ContactContext.Provider>
  );
};

export const useContact = () => {
  return useContext(ContactContext);
};
