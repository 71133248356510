import React from 'react';
import { useCollections } from 'Providers/CollectionsProviders';
import { instance } from 'services/api';
import { Button } from 'primereact/button';
import { useSessionStorageInternal } from 'Providers/SessionStorageProvider';
import './styles.css';

const OrderPaymentInCurse = ({ setLoading }) => {
  const { setOrderPaymentInCurse } = useCollections();
  const [storedValue, setStoredValue, minutesLeft, secondsLeft, reset] =
    useSessionStorageInternal();
  const goToOrderPayment = () => {
    window.open(storedValue.initPoint, '_blank');
  };

  const deleteOrdenPaymentInCurse = async () => {
    setLoading(true);
    await instance
      .post('/api/order_payment_delete', {
        preferenceId: storedValue.preferenceId,
      })
      .then((res) => {
        if (res?.data?.ok) {
          setOrderPaymentInCurse(null);
          reset();
        }
      })
      .catch((err) => console.log({ err }));
    setLoading(false);
  };
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div>Hay una orden de pago generada</div>
      <div
        style={{
          padding: '20px 0',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          label="Volver a abrir la orden de pago"
          severity="success"
          size="small"
          style={{ marginBottom: 10, width: '100%' }}
          onClick={goToOrderPayment}
          className="acceptButton"
        />
        <Button
          label="Eliminar la orden de pago pendiente"
          severity="danger"
          size="small"
          onClick={deleteOrdenPaymentInCurse}
          className="cancelButton"
        />
      </div>
      <div
        style={{ fontSize: '0.7em' }}
      >{`La orden de pago vence en: ${minutesLeft}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft} minutos`}</div>
    </div>
  );
};

export default OrderPaymentInCurse;
