import React, { useEffect, useState } from 'react';
import { useResizeListener } from 'primereact/hooks';
import LoadingContent from 'components/LoadingContent';
import Template from 'components/Template';
import Footer from 'components/Footer';
import { CollectionsProvider } from 'Providers/CollectionsProviders';
import { useLocation } from 'react-router-dom';
import ShoppingCartBadge from './components/ShoppingCartBadge';

const TemplateScreen = ({ Screen }) => {
  const { pathname } = useLocation();

  const [eventData, setEventData] = useState({ width: 0, height: 0 });
  const [bindWindowResizeListener, unbindWindowResizeListener] =
    useResizeListener({
      listener: (event) => {
        setEventData({
          width: event.currentTarget.innerWidth,
          height: event.currentTarget.innerHeight,
        });
      },
    });
  useEffect(() => {
    setEventData({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  useEffect(() => {
    bindWindowResizeListener();
    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (eventData.width === 0) {
    return <LoadingContent minHeight={window.innerHeight} />;
  }

  return (
    <div className="App">
      <CollectionsProvider>
        <Template eventData={eventData} />
        {Screen(eventData)}
        <ShoppingCartBadge eventData={eventData} />
        <Footer eventData={eventData} />
      </CollectionsProvider>
    </div>
  );
};

export default TemplateScreen;
