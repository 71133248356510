import { useState, useEffect } from 'react';

const useSessionStorageWithExpiry = (
  key,
  initialValue = null,
  expiryMinutes = 15,
  onExpire = null,
) => {
  const [value, setValue] = useState(() => {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }

    return item.value;
  });

  const [timeLeft, setTimeLeft] = useState(() => {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();
    const timeDifference = item.expiry - now.getTime();
    return Math.max(timeDifference, 0);
  });

  const reset = () => {
    sessionStorage.removeItem(key);
    setValue(null);
    setTimeLeft(null);
  };

  useEffect(() => {
    let interval;

    if (value !== null) {
      const now = new Date();
      const itemStr = sessionStorage.getItem(key);
      let expiryTime;

      if (itemStr) {
        const item = JSON.parse(itemStr);
        expiryTime = item.expiry;
      } else {
        expiryTime = now.getTime() + expiryMinutes * 60000;
        const item = {
          value: value,
          expiry: expiryTime,
        };
        sessionStorage.setItem(key, JSON.stringify(item));
      }

      setTimeLeft(expiryTime - now.getTime());

      interval = setInterval(async () => {
        const now = new Date();
        const timeDifference = expiryTime - now.getTime();
        setTimeLeft(Math.max(timeDifference, 0));

        if (timeDifference <= 0) {
          sessionStorage.removeItem(key);
          setValue(null);
          clearInterval(interval);

          if (onExpire && typeof onExpire === 'function') {
            await onExpire(value.preferenceId);
            reset();
          }
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [key, value, expiryMinutes]);

  const minutesLeft = timeLeft !== null ? Math.floor(timeLeft / 60000) : null;
  const secondsLeft =
    timeLeft !== null ? Math.floor((timeLeft % 60000) / 1000) : null;

  return [value, setValue, minutesLeft, secondsLeft, reset];
};

export default useSessionStorageWithExpiry;
