import React from 'react';
import './styles.css';
import { RadioButton } from 'primereact/radiobutton';
import { useContact } from 'Providers/ContactProvider';

const CardAddress = ({ country, state, direction, apartment, cp, _id }) => {
  const { addressIdSelected, setAddressIdSelected, deleteAddress } =
    useContact();

  const deleteAddressAction = (e) => {
    e.stopPropagation();
    if (addressIdSelected === _id) {
      setAddressIdSelected(null);
    }
    deleteAddress(_id);
  };
  return (
    <div
      className="card-address"
      onClick={() => {
        setAddressIdSelected(_id);
      }}
    >
      <div
        style={{
          padding: 8,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '100%',
          minWidth: '270px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ fontWeight: 600, paddingRight: 8 }}>{'Dirección:'}</div>
          <div>{direction}</div>
          <div style={{ paddingLeft: 8 }}>{apartment}</div>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ fontWeight: 600, paddingRight: 8 }}>
            {'Código postal:'}
          </div>
          <div>{cp}</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <div style={{ fontWeight: 600, paddingRight: 8 }}>{'Región:'}</div>
            <div style={{ paddingRight: 4 }}>{`${state} /`}</div>
            <div>{country}</div>
          </div>
          <div onClick={deleteAddressAction} className="delete-card">
            <i className="pi pi-trash" />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderLeft: '1.5px solid var(--gray-400)',
          padding: '0 8px',
          minWidth: '40px',
        }}
      >
        <RadioButton
          inputId="ingredient1"
          name="id"
          value={_id}
          checked={addressIdSelected === _id}
        />
      </div>
    </div>
  );
};

export default CardAddress;
