export const items = (collections, navigate, menuBarRef) => {
  const itemRenderer = (item) => (
    <a
      className="flex align-items-center p-menuitem-link"
      onClick={() => navigate(`/item?id=${item._id}`)}
    >
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
    </a>
  );

  const handleMouseEnter = (event) => {
    event.target.parentElement.click();
  };

  const collectionRenderer = (item) => (
    <div className="collectionButton">
      <div className="redirectToCollection">
        <a
          className="p-menuitem-link"
          onClick={(e) => {
            if (e.isTrusted) {
              if (menuBarRef.current) {
                const menuElement = menuBarRef.current.getElement();
                if (menuElement) {
                  menuElement.parentElement.click();
                }
              }
              navigate(`/collection?categoryName=${item.categoryName}`);
            }
          }}
          onMouseEnter={handleMouseEnter}
        >
          <span className={item.icon} />
          <span style={{ paddingLeft: 16, paddingRight: 4 }} className="mx-2">
            {item.label}
          </span>
        </a>
      </div>
      <div className=" arrowCollection">
        <span className="pi pi-angle-down" />
      </div>
    </div>
  );

  const subCollectionRenderer = (item) => (
    <div className="collectionButton">
      <div
        className="redirectToCollection"
        onClick={(e) => {
          if (e.isTrusted) {
            if (menuBarRef.current) {
              const menuElement = menuBarRef.current.getElement();
              if (menuElement) {
                menuElement.parentElement.click();
                const linkElement =
                  menuElement.querySelector('a.p-menubar-button');
                const ulElement = menuElement.querySelector(
                  'ul.p-menubar-root-list',
                );
                if (ulElement) {
                  ulElement.childNodes.forEach((liElement) => {
                    liElement.classList.remove('p-menuitem-active');
                    liElement.classList.remove('p-highlight');
                    liElement.setAttribute('data-p-highlight', 'false');
                    liElement.setAttribute('aria-expanded', 'false');
                  });
                  ulElement.removeAttribute('aria-activedescendant');
                  ulElement.removeAttribute('style');
                }
                if (linkElement) {
                  linkElement.setAttribute('aria-haspopup', 'false');
                  linkElement.setAttribute('aria-expanded', 'false');

                  menuElement.classList.remove('p-menubar-mobile-active');
                }
              }
            }
            navigate(
              `/collection?categoryName=${item.categoryName}&subCategoryName=${item.subCategoryName}`,
            );
          }
        }}
      >
        <a className="p-menuitem-link">
          <span className="mx-2" style={{ paddingLeft: 16, paddingRight: 4 }}>
            {item.label}
          </span>
        </a>
      </div>
      <div className=" arrowCollection">
        <span className={item.icon} />
      </div>
    </div>
  );

  const panelCollections = { Pintura: [], Dibujo: [], Boceto: [] };
  collections?.map((item) => {
    if (item.subCategory === 'empty' || !item.subCategory) {
      panelCollections[`${item.category}`]?.push({
        label: item.name,
        _id: item._id,
        template: itemRenderer,
      });
    } else {
      const element = panelCollections[`${item.category}`]?.find(
        (element) => element.label === item.subCategory,
      );
      if (element) {
        panelCollections[`${item.category}`] = panelCollections[
          `${item.category}`
        ].map((elementCat) => {
          if (elementCat.label === item.subCategory) {
            return {
              label: item.subCategory,
              template: subCollectionRenderer,
              categoryName: item.category,
              subCategoryName: item.subCategory,
              icon: 'pi pi-angle-right',
              items: [
                ...element.items,
                {
                  label: item.name,
                  _id: item._id,
                  template: itemRenderer,
                },
              ],
            };
          } else {
            return elementCat;
          }
        });
      } else {
        panelCollections[`${item.category}`]?.push({
          label: item.subCategory,
          template: subCollectionRenderer,
          categoryName: item.category,
          subCategoryName: item.subCategory,
          icon: 'pi pi-angle-right',
          items: [
            {
              label: item.name,
              _id: item._id,
              template: itemRenderer,
            },
          ],
        });
      }
    }
  });
  const subCategories = ['Retratos', 'Mascotas', 'Vermilion'];
  const sortPanel = (arrayCollection = []) =>
    arrayCollection.sort((a, b) => {
      const priorityA = subCategories.includes(a.label) ? 0 : 1;
      const priorityB = subCategories.includes(b.label) ? 0 : 1;
      return priorityA - priorityB;
    });
  return [
    {
      label: 'Pinturas',
      icon: 'pi pi-palette',
      categoryName: 'Pintura',
      template: collectionRenderer,
      items: sortPanel(panelCollections['Pintura']),
    },
    {
      label: 'Dibujos',
      icon: 'fa-solid fa-pencil',
      categoryName: 'Dibujo',
      template: collectionRenderer,
      items: sortPanel(panelCollections['Dibujo']),
    },
    {
      label: 'Bocetos',
      icon: 'fa-solid fa-eraser',
      categoryName: 'Boceto',
      template: collectionRenderer,
      items: sortPanel(panelCollections['Boceto']),
    },
  ];
};
