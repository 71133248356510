import React from 'react';
import SearchResultView from './components/SearchResultView';
import TemplateScreen from 'screens/TemplateScreen';

const SearchResult = () => {
  return (
    <TemplateScreen
      Screen={(eventData) => <SearchResultView eventData={eventData} />}
    />
  );
};

export default SearchResult;
