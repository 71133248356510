export const categories = ['Pintura', 'Dibujo', 'Boceto'];

export const isSearchContained = (search, name) => {
  const normalizeString = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const normalizedSearch = normalizeString(search);
  const normalizedName = normalizeString(name);
  const escapedSearch = normalizedSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(escapedSearch, 'i');

  return regex.test(normalizedName);
};
