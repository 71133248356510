import React, { useState } from 'react';
import LoadingContent from 'components/LoadingContent';
import { useCollections } from 'Providers/CollectionsProviders';
import './styles.css';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { instance } from 'services/api';
import { initMercadoPago } from '@mercadopago/sdk-react';
import ListItemCard from './components/ListItemCard';
import { useSessionStorageInternal } from 'Providers/SessionStorageProvider';
import { useContact } from 'Providers/ContactProvider';

const ShoppingCartView = ({ eventData, toast }) => {
  initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);
  const { shoppingCart } = useCollections();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [storedValue, setStoredValue] = useSessionStorageInternal();
  const { addresses, addressIdSelected, contactInfo } = useContact();

  if (!shoppingCart || loading) {
    return <LoadingContent minHeight={eventData.height * 0.65} />;
  }
  const goToHome = () => navigate('/');

  const goToMercadoPagoPayment = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('shoppingCart', JSON.stringify(shoppingCart));
    formData.append(
      'contactInfo',
      JSON.stringify({
        email: contactInfo.email,
        firstName: contactInfo.firstName,
        lastName: contactInfo.lastName,
        phone: contactInfo.phone,
        shippingType: contactInfo.shippingType,
      }),
    );
    formData.append(
      'address',
      JSON.stringify(
        addresses.find((address) => address._id === addressIdSelected),
      ),
    );
    await instance
      .post('/api/payments', formData)
      .then((res) => {
        if (res.data.body.initPoint) {
          setStoredValue({
            initPoint: res.data.body.initPoint,
            preferenceId: res.data.body.preferenceId,
          });
          window.open(res.data.body.initPoint, '_blank');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          toast.current.show({
            severity: 'warn',
            summary: 'Artículos reservados',
            detail: `${err.response.data.error}`,
            life: 5000,
          });
        }
      });
    setLoading(false);
  };
  const mainImageSize = () => {
    if (eventData.width < 480) {
      return {
        width: '90%',
        minHeight: undefined,
      };
    }
    if (eventData.width <= 768) {
      return {
        width: '90%',
        minHeight: undefined,
      };
    }
    return {
      width: eventData.width * 0.85,
      minHeight: eventData.height * 0.55,
    };
  };

  return shoppingCart.length === 0 ? (
    <div
      style={{
        color: 'var(--gray-800)',
        fontSize: '1.5em',
        minHeight: eventData.height * 0.65,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>No hay artículos en el carrito.</div>
      <div onClick={goToHome} className={styles.goToHome}>
        Ir a la pagina principal
      </div>
    </div>
  ) : (
    <div
      style={{
        color: 'var(--gray-800)',
        fontSize: '1.5em',
        minHeight: mainImageSize().minHeight,
        width: mainImageSize().width,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: 16,
      }}
      className="listItemCardContainer"
    >
      <ListItemCard
        eventData={eventData}
        goToMercadoPagoPayment={goToMercadoPagoPayment}
        setLoading={setLoading}
        storedValue={storedValue}
      />
    </div>
  );
};

export default ShoppingCartView;
