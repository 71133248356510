import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'primereact/resources/themes/viva-dark/theme.css';
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import { CollectionsProvider } from 'Providers/CollectionsProviders';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from 'screens/Home';
import ItemView from 'screens/ItemView';
import Collection from 'screens/Collection';
import SearchResult from 'screens/SearchResult';
import ShoppingCart from 'screens/ShoppingCart';
import { SessionStorageProvider } from 'Providers/SessionStorageProvider';
import { ContactProvider } from 'Providers/ContactProvider';
import Resolution from 'screens/Resolution';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'item',
    element: <ItemView />,
  },
  {
    path: 'collection',
    element: <Collection />,
  },
  {
    path: 'search',
    element: <SearchResult />,
  },
  {
    path: 'shopping_cart',
    element: <ShoppingCart />,
  },
  {
    path: 'resolution',
    element: <Resolution />,
  },
]);

root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <CollectionsProvider>
        <SessionStorageProvider>
          <ContactProvider>
            <RouterProvider router={router} />
          </ContactProvider>
        </SessionStorageProvider>
      </CollectionsProvider>
    </PrimeReactProvider>
  </React.StrictMode>,
);
