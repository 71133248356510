import React, { useRef, useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
import { items } from './utils';
import styles from './styles';
import { Button } from 'primereact/button';

import dioArtLogo from '../../assets/dio_art_logo.svg';
import { useCollections } from 'Providers/CollectionsProviders';
import { Link, useNavigate } from 'react-router-dom';
import './styles.css';

const Template = ({ eventData }) => {
  const navigate = useNavigate();
  const { newSearch } = useCollections();
  const [search, setSearch] = useState('');
  const menuBarRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (location.pathname !== 'search')
        navigate(`/search?search=${event.target.value}`);
    }
  };
  const goToSearch = () => {
    if (location.pathname !== 'search') {
      navigate(`/search?search=${search}`);
      setSearch('');
    }
  };

  const start = (
    <Link to="/" style={{ paddingRight: 8 }}>
      <img alt="logo" src={dioArtLogo} height="40" className="mr-2"></img>
    </Link>
  );

  const end = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <div className="p-inputgroup flex-1 w-8rem sm:w-auto">
        <InputText
          placeholder="Buscar"
          type="text"
          style={{
            backgroundColor: 'var(--gray-600)',
            borderColor: 'var(--gray-400)',
            borderRightWidth: 0,
          }}
          onChange={(e) => {
            newSearch(e.target.value);
            setSearch(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          className="searchInput"
          id="searchInput"
        />
        <Button
          icon="pi pi-search"
          className="p-button search"
          style={{
            backgroundColor: 'var(--gray-800)',
            borderColor: 'var(--gray-400)',
            color: 'var(--gray-400)',
          }}
          onClick={goToSearch}
        />
      </div>
      {/* <InputText placeholder="Buscar" type="text" className="" style={{backgroundColor: 'var(--gray-600)'}} /> */}
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          paddingLeft: 16,
        }}
      >
        <Avatar
          image="https://dioart.s3.us-east-1.amazonaws.com/perfil.png"
          shape="circle"
          size="large"
        />
      </div>
    </div>
  );
  const collections = useCollections()?.collections;
  return (
    <div className="card">
      <Menubar
        model={items(collections, navigate, menuBarRef)}
        start={start}
        end={end}
        s="gray-300"
        style={{
          ...styles.menu,
          width: eventData.width,
          border: 0,
          paddingLeft: 16,
          paddingRight: 16,
        }}
        ref={menuBarRef}
        id="menuBar"
      />
    </div>
  );
};

export default Template;
