import { InputText } from 'primereact/inputtext';
import React from 'react';
import './styles.css';
import { useContact } from 'Providers/ContactProvider';

const ContactDataForm = () => {
  const { updateContactInfo, contactInfo, fieldsError } = useContact();
  return (
    <div style={{ paddingBottom: 16 }}>
      <label
        style={{
          width: '100%',
          display: 'flex',
          fontWeight: 600,
          fontSize: '1em',
        }}
      >
        Contacto
      </label>
      <div className="input-div-container" style={{ width: '100%' }}>
        <label className="float-label" htmlFor="email">
          Correo electrónico
        </label>
        <InputText
          id="email"
          className="p-inputtext-sm"
          style={{ width: '100%' }}
          onChange={(e) =>
            updateContactInfo({ type: 'email', value: e.target.value })
          }
          value={contactInfo?.email}
          invalid={fieldsError?.email ?? false}
        />
      </div>

      <div className="input-div-container" style={{ width: '100%' }}>
        <label className="float-label" htmlFor="firstName">
          Nombre
        </label>
        <InputText
          id="firstName"
          className="p-inputtext-sm"
          style={{ width: '100%' }}
          onChange={(e) =>
            updateContactInfo({ type: 'firstName', value: e.target.value })
          }
          value={contactInfo?.firstName}
          invalid={fieldsError?.firstName ?? false}
        />
      </div>

      <div className="input-div-container" style={{ width: '100%' }}>
        <label className="float-label" htmlFor="lastName">
          Apellido
        </label>
        <InputText
          id="lastName"
          className="p-inputtext-sm"
          style={{ width: '100%' }}
          onChange={(e) =>
            updateContactInfo({ type: 'lastName', value: e.target.value })
          }
          value={contactInfo?.lastName}
          invalid={fieldsError?.lastName ?? false}
        />
      </div>

      <div className="input-div-container" style={{ width: '100%' }}>
        <label className="float-label" htmlFor="phone">
          Teléfono
        </label>
        <InputText
          id="phone"
          className="p-inputtext-sm"
          style={{ width: '100%' }}
          onChange={(e) =>
            updateContactInfo({ type: 'phone', value: e.target.value })
          }
          value={contactInfo?.phone}
          invalid={fieldsError?.phone ?? false}
        />
      </div>
    </div>
  );
};

export default ContactDataForm;
