import React from 'react';
import Item from './Item';
import TemplateScreen from 'screens/TemplateScreen';

const ItemView = () => {
  return (
    <TemplateScreen Screen={(eventData) => <Item eventData={eventData} />} />
  );
};

export default ItemView;
