import React from 'react';
import CollectionView from './components/CollectionView';
import TemplateScreen from 'screens/TemplateScreen';

const Collection = () => {
  return (
    <TemplateScreen
      Screen={(eventData) => <CollectionView eventData={eventData} />}
    />
  );
};

export default Collection;
