import { useContact } from 'Providers/ContactProvider';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import CardAddress from './components/CardAddress';
import './styles.css';

const SelectionAddress = ({ setNewDirectionFlow }) => {
  const { addresses } = useContact();

  const goToNewDirectionFlow = () => setNewDirectionFlow(true);

  return (
    <div className="container">
      <div style={{ alignSelf: 'flex-start', paddingBottom: 18 }}>
        {'Selecciona una dirección:'}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          maxHeight: '450px',
          overflowY: 'auto',
        }}
        className="addressContainer"
      >
        {addresses.map((address) => (
          <CardAddress key={uuidv4()} {...address} />
        ))}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 120,
            backgroundColor: 'var(--gray-50)',
            marginBottom: 16,
            padding: 8,
            borderRadius: 5,
            border: '2px solid var(--gray-200)',
            cursor: 'pointer',
          }}
          className="newDirection"
          onClick={goToNewDirectionFlow}
        >
          <i
            className="pi pi-plus-circle"
            style={{ fontSize: '1em', paddingRight: 16 }}
          />
          <div>{'Nueva dirección'}</div>
        </div>
      </div>
    </div>
  );
};

export default SelectionAddress;
