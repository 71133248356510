import React from 'react';
import logo from '../../assets/dio_art_logo_black.svg';
import GaleryCollections from './components/GaleryCollections';

const ContainerCollections = ({ eventData }) => {
  return (
    <div
      style={{
        minHeight: eventData.height * 0.7,
        minWidth: eventData.width,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{ paddingTop: 16, paddingBottom: 16, justifyContent: 'center' }}
      >
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <GaleryCollections />
    </div>
  );
};

export default ContainerCollections;
