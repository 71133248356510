import React from 'react';
import { DataScroller } from 'primereact/datascroller';
import { itemTemplate } from '../ItemTemplate';
import ContactData from './components/ContactData';
import OrderPaymentInCurse from './components/OrderPaymentInCurse';
import { useCollections } from 'Providers/CollectionsProviders';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const ListItemCard = ({
  eventData,
  goToMercadoPagoPayment,
  storedValue,
  setLoading,
}) => {
  const { shoppingCart } = useCollections();
  const navigate = useNavigate();
  const mainImageSize = () => {
    if (eventData.width <= 360) {
      return {
        container: {
          minWidth: 340,
          scrollHeight: '100%',
        },
      };
    }
    if (eventData.width < 480) {
      return {
        container: {
          minWidth: 400,
          scrollHeight: '100%',
        },
      };
    }
    if (eventData.width <= 768) {
      return {
        container: {
          minWidth: 400,
          scrollHeight: '100%',
        },
      };
    }
    if (eventData.width <= 968) {
      return {
        container: {
          minWidth: 400,
          scrollHeight: '100%',
        },
      };
    }
    return {
      container: {
        minWidth: 600,
        scrollHeight: `${eventData.height * 0.8}px`,
      },
    };
  };
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'flex-start',
      }}
      className="container"
    >
      <div
        style={{
          padding: '0 16px 0 16px',
          minWidth: mainImageSize().container.minWidth,
        }}
        className="containerDataScroller"
      >
        <DataScroller
          value={shoppingCart}
          itemTemplate={(item) =>
            itemTemplate(item, navigate, storedValue, eventData)
          }
          rows={shoppingCart.length}
          scrollHeight={mainImageSize().container.scrollHeight}
          inline
          header={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: '4px 0 4px 0',
              }}
              className="shoppingCartTitle"
            >
              <div>{'Carrito de compras'}</div>
              {/* <div>{`${shoppingCart.length} Items`}</div> */}
            </div>
          }
          emptyMessage="Cargando elementos..."
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minWidth: mainImageSize().container.minWidth,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '2px 0px 2px 0px',
          }}
          className="buyDetail"
        >
          {'Detalle de compra'}
        </div>
        <div
          style={{
            width: '100%',
            minHeight: `${eventData.height * 0.572}px`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingTop: 20,
            fontSize: '0.7em',
            fontFamily: 'sans-serif',
            textAlign: 'right',
          }}
        >
          {!storedValue?.preferenceId ? (
            <ContactData
              goToMercadoPagoPayment={goToMercadoPagoPayment}
              eventData={eventData}
            />
          ) : (
            <OrderPaymentInCurse setLoading={setLoading} />
          )}
        </div>
      </div>
    </div>
  );
};
export default ListItemCard;
