import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCollections } from 'Providers/CollectionsProviders';
import Item from 'components/ContainerCollections/components/GaleryCollections/components/Item';
import { v4 as uuidv4 } from 'uuid';
import { Skeleton } from 'primereact/skeleton';
import LoadingContent from 'components/LoadingContent';
import styles from './styles.module.css';

const CollectionView = ({ eventData }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const categoryName = urlParams.get('categoryName');
  const subCategoryName = urlParams.get('subCategoryName');
  const navigate = useNavigate();
  const collections = useCollections()?.collections;
  let galleryCategory = collections?.filter(
    (item) => item.category === categoryName,
  );
  if (subCategoryName) {
    galleryCategory = galleryCategory?.filter(
      (item) => item.subCategory === subCategoryName,
    );
  }
  if (!collections) {
    return <LoadingContent minHeight={eventData.height * 0.65} />;
  }
  const arrayObjects = Array(6).fill({});
  if (!galleryCategory) {
    return arrayObjects.map(() => (
      <Skeleton width="10rem" height="4rem" borderRadius="16px"></Skeleton>
    ));
  }
  const goToCollection = () => () => {
    navigate(
      `/collection?categoryName=${categoryName}${subCategoryName ? `&subCategoryName=${subCategoryName}` : ''}`,
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          fontSize: '2em',
          fontWeight: 600,
          paddingBottom: 12,
          paddingTop: 24,
        }}
        className={styles.collection}
      >
        <p
          style={{ color: 'var(--gray-900)', cursor: 'pointer' }}
          onClick={goToCollection()}
        >
          {`${categoryName}s ${subCategoryName ? `/ ${subCategoryName}` : ''}`}
        </p>
      </div>

      <div
        style={{
          width: eventData.width * 0.9,
          minHeight: eventData.height * 0.7,
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {galleryCategory &&
          galleryCategory
            .sort((a, b) => {
              return parseInt(b.completionDate) - parseInt(a.completionDate);
            })
            .map((item) => (
              <div style={{ paddingBottom: 16 }}>
                <Item key={uuidv4()} item={item} />
              </div>
            ))}
      </div>
    </div>
  );
};

export default CollectionView;
