import React, { useMemo } from 'react';
import { formatNumberWithDots } from 'components/ContainerCollections/components/GaleryCollections/components/Item/utils';

import styles from './styles.module.css';
import { mpIconUrl } from './components/MpIcon';
import { useCollections } from 'Providers/CollectionsProviders';
import { Button } from 'primereact/button';
import { useContact } from 'Providers/ContactProvider';

const PaymentComponent = ({ goToMercadoPagoPayment }) => {
  const { shoppingCart } = useCollections();
  const { addresses, addressIdSelected, contactInfo } = useContact();

  const orderPaymentButtonDisabled = useMemo(() => {
    if (
      !!contactInfo?.email &&
      !!contactInfo?.firstName &&
      !!contactInfo?.lastName &&
      !!contactInfo?.phone &&
      !!contactInfo?.shippingType
    ) {
      if (contactInfo?.shippingType === 'pickup') {
        return false;
      } else {
        if (contactInfo?.shippingType === 'delivery') {
          if (!!addressIdSelected && addresses.length > 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }, [contactInfo, addressIdSelected, addresses]);
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          fontWeight: '600',
        }}
      >
        {'Total'}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {`$${formatNumberWithDots(
          shoppingCart.reduce(
            (total, itemCart) => total + itemCart.item.price,
            0,
          ),
        )} ARS`}
      </div>
      <Button
        onClick={goToMercadoPagoPayment}
        style={{ marginTop: 8, width: '100%', flexWrap: 'nowrap' }}
        className={styles.pButtonLabel}
        label="Terminar y pagar"
        severity="info"
        raised
        id="buttomMp"
        disabled={orderPaymentButtonDisabled}
      >
        <div
          style={{
            minWidth: '30%',
            width: 32,
            height: 32,
            marginRight: 16,
            backgroundImage: `url(${mpIconUrl})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Button>
      {orderPaymentButtonDisabled && (
        <div
          style={{
            paddingTop: 4,
            width: '100%',
            fontSize: '.8em',
            textAlign: 'center',
            color: 'red',
          }}
        >
          Completa los datos de contacto y envío para poder pagar
        </div>
      )}
    </div>
  );
};

export default PaymentComponent;
