import React, { Fragment, useState } from 'react';
import PaymentComponent from './components/PaymentComponent';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { RadioButton } from 'primereact/radiobutton';
import DeliveryDataForm from './components/DeliveryDataForm';
import ContactDataForm from './components/ContactDataForm';
import { useContact } from 'Providers/ContactProvider';
import styles from './styles.module.css';
import './styles.css';
import SelectionAddress from './components/SelectionAddress';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const ContactData = ({ goToMercadoPagoPayment }) => {
  const { updateContactInfo, addresses, contactInfo } = useContact();
  const [newDirectionFlow, setNewDirectionFlow] = useState(
    addresses.length === 0,
  );

  return (
    <Fragment>
      <div style={{ width: '100%', paddingBottom: 16 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: '100%',
            color: 'var(--gray-800)',
          }}
        >
          <ContactDataForm />
          <Accordion
            style={{
              width: '100%',
            }}
            expandIcon={({ selected }) => {
              return <RadioButton checked={selected} />;
            }}
            collapseIcon={({ selected }) => {
              return <RadioButton checked={selected} />;
            }}
            className={styles.mypanel}
            activeIndex={
              contactInfo?.shippingType === 'pickup'
                ? 0
                : contactInfo?.shippingType === 'delivery'
                  ? 1
                  : null
            }
            onTabChange={(e) => {
              e.index === 0
                ? updateContactInfo({ type: 'shippingType', value: 'pickup' })
                : e.index === 1
                  ? updateContactInfo({
                      type: 'shippingType',
                      value: 'delivery',
                    })
                  : updateContactInfo({ type: 'shippingType', value: '' });
            }}
          >
            <AccordionTab
              headerTemplate={() => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div>Retiro en domicilio</div>
                </div>
              )}
            >
              <div style={{ fontSize: '0.9em' }}>
                {
                  'Luego de realizar el pago contactame por medio de whatsapp para coordinar la entrega de tu compra'
                }
              </div>
            </AccordionTab>
            <AccordionTab
              headerTemplate={() => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div>Envío</div>
                </div>
              )}
              className="last-item"
            >
              <SwitchTransition>
                <CSSTransition
                  key={!newDirectionFlow}
                  timeout={250}
                  classNames="fade"
                >
                  <div className="addressSelectionContainer">
                    {!newDirectionFlow ? (
                      <SelectionAddress
                        setNewDirectionFlow={setNewDirectionFlow}
                      />
                    ) : (
                      <DeliveryDataForm
                        setNewDirectionFlow={setNewDirectionFlow}
                      />
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
              <div
                style={{
                  fontSize: '0.6em',
                  paddingBottom: 8,
                  width: '100%',
                }}
              >{`(El precio incluye solo envío gratuito dentro de CABA, Buenos Aires)`}</div>
              <div
                style={{ fontSize: '0.6em' }}
              >{`Envíos para GBA y el resto del país a cargo del comprador.`}</div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
      <PaymentComponent goToMercadoPagoPayment={goToMercadoPagoPayment} />
    </Fragment>
  );
};

export default ContactData;
